import {
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DELETE_START,
    CATEGORY_DELETE_ERROR
} from '../../../constants/index';
import { deleteCategoryMutation, getAllCategoryqQuery } from '../../../lib/graphql';
import { setLoaderComplete, setLoaderStart } from '../../loader/loader'
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

const deleteCategory = (id, currentPage) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: CATEGORY_DELETE_START
            });

            dispatch(setLoaderStart('DeleteCategory'));

            const { data } = await client.mutate({
                mutation: deleteCategoryMutation,
                variables: {
                    id
                },
                refetchQueries: [{ query: getAllCategoryqQuery, variables: { currentPage, searchList: '' } }]
            });

            dispatch(setLoaderComplete('DeleteCategory'));

            if (data?.deleteCategory?.status == 200) {
                dispatch({
                    type: CATEGORY_DELETE_SUCCESS
                });
                showToaster({ messageId: 'removeCatSuccess', toasterType: 'success' });
            } else {
                dispatch({
                    type: CATEGORY_DELETE_ERROR
                });
                showToaster({ messageId: 'errorMessage', requestContent: data?.deleteCategory?.errorMessage, toasterType: 'error' });
            }
        } catch (err) {
            showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
            dispatch({
                type: CATEGORY_DELETE_ERROR
            });
        }

    }
};

export default deleteCategory;