import gql from "graphql-tag";

export const uploadIdentityImageMutation = gql`
mutation uploadIdentityImage($userId:ID,$imageName:String, $type: String){
	uploadIdentityImage(userId:$userId, imageName:$imageName, type: $type) {
        status
        errorMessage
        experienceDoc{
            imageName
        }
        identityDoc{
            imageName
        }
    }
}`;

export const removeExperienceDocumentMutation = gql`
mutation removeExperienceDocument($imageName:String, $userId: String){
	removeExperienceDocument(imageName:$imageName, userId:$userId) {
        status
        errorMessage
        experienceDoc{
            imageName
        }
        identityDoc{
            imageName
        }
    }
}`;