import {
    HOMEPAGE_SETTINGS_UPLOAD_START,
    HOMEPAGE_SETTINGS_UPLOAD_ERROR,
    HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index'
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import { updateHomePageSignupMutation } from '../../lib/graphql';
import showToaster from '../../helpers/toastrMessgaes/showToaster';

const updateHomepageSettingsSignup = (values) => {

    return async (dispatch, getState, { client }) => {

        try {

            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_START
            })

            dispatch(setLoaderStart('SignupSettingsForm'))
            const { data } = await client.mutate({
                mutation: updateHomePageSignupMutation,
                variables: {
                    signupGridImage1: values?.signupGridImage1,
                    signupGridImage2: values?.signupGridImage2,
                    signupGridImage3: values?.signupGridImage3,
                    signupGridTitle1: values?.signupGridTitle1,
                    signupGridContent1: values?.signupGridContent1,
                    signupGridLink1: values?.signupGridLink1,
                    signupGridLink2: values?.signupGridLink2,
                    signupGridImage4: values?.signupGridImage4
                }
            })

            dispatch(setLoaderComplete('SignupSettingsForm'))
            if (data?.updateHomePageSignup?.status == 200) {

                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
                })
                showToaster({ messageId: 'updateCitySuccess', toasterType: 'success' });
            } else {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
                })
                showToaster({ messageId: 'errorMessage', requestContent: data?.updateHomePageSignup?.errorMessage, toasterType: 'error' });
            }
        } catch (err) {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
            })
            showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
        }
    }
}

export default updateHomepageSettingsSignup;