import {
  DELETE_PRICING_START,
  DELETE_PRICING_SUCCESS,
  DELETE_PRICING_ERROR
} from '../../../constants';
import { deletePricingMutation } from '../../../lib/graphql';
import showErrorMessage from '../../../helpers/showErrorMessage';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

export const deletePricing = (id) => {

  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: DELETE_PRICING_START,
        payload: {
          pricingLoading: true
        }
      });

      const { data } = await client.mutate({
        mutation: deletePricingMutation,
        variables: {
          id
        }
      });

      if (data?.deletePricing?.status === 200) {
        showToaster({ messageId: 'delFareSuccess', toasterType: 'success' });

        await dispatch({
          type: DELETE_PRICING_SUCCESS,
          payload: {
            pricingLoading: false
          }
        });

        return await {
          status: 200
        };
      } else {
        showToaster({ messageId: 'errorMessage', requestContent: data?.deletePricing?.errorMessage, toasterType: 'error' });

        await dispatch({
          type: DELETE_PRICING_ERROR,
          payload: {
            pricingLoading: false,
            error: data?.deletePricing?.errorMessage
          }
        });

        return await {
          status: 400
        };
      }
    } catch (error) {
      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });

      await dispatch({
        type: DELETE_PRICING_ERROR,
        payload: {
          pricingLoading: false,
          error: showErrorMessage({ errorCode: 'catchError', error })
        }
      });

      return await {
        status: 400
      };
    }
  }
}