import gql from 'graphql-tag';

export const getCurrencyRatesQuery = gql`{
    getCurrencyRates {
        base
        rates
    }
  }`;

export const getBaseCurrencySubQuery = gql`
  {
      getBaseCurrency{
        id
        symbol
      }
  }`;

export const getCurrencyQuery = gql`
query getCurrency($currentPage: Int, $searchList: String){
    getCurrency(currentPage: $currentPage, searchList:$searchList){
      count
      results{
          id
          symbol
          isEnable
          isPayment
          isBaseCurrency
      }
    }
  }`;

export const updateCurrencyMutation = gql`
mutation updateCurrency ($id: Int, $isEnable: Boolean, $symbol: String){
  updateCurrency(id: $id, isEnable: $isEnable, symbol: $symbol){
        status
        errorMessage
    }
}`;

export const setBaseCurrencyMutation = gql`
mutation setBaseCurrency($id: Int){
  setBaseCurrency(id: $id){
        status
    }
}`;

export const allowPaymentCurrencyMutation = gql`
mutation allowPaymentCurrency($id: Int,$isPayment: Int){
  allowPaymentCurrency(id: $id,isPayment: $isPayment){
        status
    }
}`;

export const removeCurrencyMutation = gql`
mutation removeCurrency ($id: Int, $symbol: String){
  removeCurrency(id: $id, symbol: $symbol){
        status
        errorMessage
    }
}`;