import {
  SET_PAYOUT_START,
  SET_PAYOUT_SUCCESS,
  SET_PAYOUT_ERROR,
  UPDATE_PAYMENT_STATUS_START,
  UPDATE_PAYMENT_STATUS_SUCCESS,
  UPDATE_PAYMENT_STATUS_ERROR
} from '../../../constants';
import {
  updatePayoutMutation,
  getPayoutListQuery,
  getAllPaymentMethodsQuery,
  updatePaymentMutation
} from '../../../lib/graphql';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

const updatePayoutStatus = (id, isBanStatus) => {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: SET_PAYOUT_START,
      });

      const { data } = await client.mutate({
        mutation: updatePayoutMutation,
        variables: { id, isBanStatus },
        refetchQueries: [{ query: getPayoutListQuery, variables: { currentPage: '1', searchList: '' } }]
      });

      if (data.updatePayout.status === 200) {

        dispatch({
          type: SET_PAYOUT_SUCCESS,
        });

        showToaster({ messageId: 'updatePayout', toasterType: 'success' });

      } else {

        dispatch({
          type: SET_PAYOUT_ERROR,
          payload: {
            error
          }
        });

        showToaster({ messageId: 'errorMessage', requestContent: data?.updatePayout?.errorMessage, toasterType: 'error' });

        return false;
      }
    } catch (error) {

      dispatch({
        type: SET_PAYOUT_ERROR,
        payload: {
          error
        }
      });

      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });

      return false;
    }
    return true;
  };
}

const updatePaymentMethod = (id, status) => {
  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: UPDATE_PAYMENT_STATUS_START,
      });

      const { data } = await client.mutate({
        mutation: updatePaymentMutation,
        variables: { id, status },
        refetchQueries: [{ query: getAllPaymentMethodsQuery }]
      });

      if (data?.updatePayment?.status === 200) {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_SUCCESS,
        });
        showToaster({ messageId: 'updatePaymentStatus', toasterType: 'success' })
      } else {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_ERROR,
          payload: {
            error: data?.updatePayment?.errorMessage
          }
        });
        showToaster({ messageId: 'paymentStatusFailed', requestContent: data?.updatePayment?.errorMessage, toasterType: 'error' })
        return false;
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PAYMENT_STATUS_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'updateStatusFailed', toasterType: 'error' })
      return false;
    }
    return true;
  };
}

export { updatePayoutStatus, updatePaymentMethod };