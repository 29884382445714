import { defineMessages } from 'react-intl';

const messages = defineMessages({
    login: {
        id: 'app.login',
        defaultMessage: 'Login',
        description: 'Login Nav link',
    },
    contact: {
        id: 'app.contact',
        defaultMessage: 'Contact',
        description: 'Contact Nav link',
    },
    logo: {
        id: 'app.logo',
        defaultMessage: 'Logo',
        description: 'Logo'
    },
    siteSettings: {
        id: 'app.siteSettings',
        defaultMessage: 'Site Settings',
        description: 'Site Settings'
    },
    HomeSiteSettings: {
        id: 'app.HomeSiteSettings',
        defaultMessage: 'Homepage Settings',
        description: 'HomeSiteSettings'
    },
    messageRequired: {
        id: 'app.messageRequired',
        defaultMessage: 'Message is required',
        description: 'Message is required'
    },
    messageLength: {
        id: 'app.messageLength',
        defaultMessage: 'Message must be 200 characters or less',
        description: 'Message must be 200 characters or less'
    },
    metaKeyword: {
        id: 'app.metaKeyword',
        defaultMessage: 'Keyword must be 255 characters or less',
        description: 'Keyword must be 255 characters or less'
    },
    metaTitle: {
        id: 'app.metaTitle',
        defaultMessage: 'Meta Title',
        description: 'Meta Title'
    },
    metaDescriptionText: {
        id: 'app.metaDescriptionText',
        defaultMessage: 'Meta Description',
        description: 'Meta Description'
    },
    metaDescription: {
        id: 'app.metaDescription',
        defaultMessage: 'Description must be 255 characters or less',
        description: 'Description must be 255 characters or less'
    },
    pageTitle: {
        id: 'app.pageTitle',
        defaultMessage: 'Page Title',
        description: 'Page Title'
    },
    pageUrl: {
        id: 'app.pageUrl',
        defaultMessage: 'Page URL',
        description: 'Page URL'
    },
    invalidUrl: {
        id: 'app.invalidUrl',
        defaultMessage: 'Invalid Page URL',
        description: 'Invalid Page URL'
    },
    ratings: {
        id: 'app.ratings',
        defaultMessage: 'Ratings',
        description: 'Ratings'
    },
    reasonRequired: {
        id: 'app.reasonRequired',
        defaultMessage: 'Reason is required',
        description: 'Reason is required'
    },
    exceedLimit100: {
        id: 'app.exceedLimit100',
        defaultMessage: 'Maximum 100 words',
        description: 'Maximum 100 words'
    },
    exceedLimit: {
        id: 'app.exceedLimit',
        defaultMessage: 'Exceeding character limit',
        description: 'Exceeding character limit'
    },
    exceedLimitTitle: {
        id: 'app.exceedLimitTitle',
        defaultMessage: 'Limit Exceeded! Please enter a maximum of 250 characters.',
        description: 'Limit Exceeded! Please enter a maximum of 250 characters.'
    },
    cancelReason: {
        id: 'app.cancelReason',
        defaultMessage: 'Cancel Reason',
        description: 'Cancel Reason'
    },
    cancelledBy: {
        id: 'app.cancelledBy',
        defaultMessage: 'Cancelled By',
        description: 'Cancelled By'
    },
    changePassword: {
        id: 'app.changePassword',
        defaultMessage: 'Change Password',
        description: 'Change Password'
    },
    addCancelReason: {
        id: 'app.addCancelReason',
        defaultMessage: 'Add Cancel Reason',
        description: 'Add Cancel Reason'
    },
    editCancelReason: {
        id: 'app.editCancelReason',
        defaultMessage: 'Edit Cancel Reason',
        description: 'Edit Cancel Reason'
    },
    reason: {
        id: 'app.reason',
        defaultMessage: 'Reason',
        description: 'Reason'
    },
    manageNotifications: {
        id: 'app.manageNotifications',
        defaultMessage: 'Manage Notifications',
        description: 'Manage Notifications'
    },
    adminpanel: {
        id: 'app.adminpanel',
        defaultMessage: 'Admin Panel',
        description: 'admin panel',
    },
    dashboard: {
        id: 'app.dashboard',
        defaultMessage: 'Dashboard',
        description: 'dashboard',
    },
    settings: {
        id: 'app.settings',
        defaultMessage: 'Settings',
        description: 'settings',
    },
    users: {
        id: 'app.users',
        defaultMessage: 'Users',
        description: 'users',
    },
    formssidebar: {
        id: 'app.formssidebar',
        defaultMessage: 'Forms',
        description: 'formssidebar',
    },
    email: {
        id: 'app.emailaddress',
        defaultMessage: 'Email Address',
        description: 'emailaddress',
    },
    emailaddress: {
        id: 'app.emailaddress',
        defaultMessage: 'Email Address',
        description: 'emailaddress',
    },
    password: {
        id: 'app.password',
        defaultMessage: 'Password',
        description: 'password',
    },
    select: {
        id: 'app.select',
        defaultMessage: 'Select',
        description: 'select',
    },
    textarea: {
        id: 'app.textarea',
        defaultMessage: 'Text Area',
        description: 'textarea',
    },
    firstName: {
        id: 'app.firstName',
        defaultMessage: 'First Name',
        description: 'firstName',
    },
    lastName: {
        id: 'app.lastName',
        defaultMessage: 'Last Name',
        description: 'lastName',
    },
    phoneNumber: {
        id: 'app.phoneNumber',
        defaultMessage: 'Phone Number',
        description: 'phoneNumber',
    },
    profilePicture: {
        id: 'app.profilePicture',
        defaultMessage: 'Profile Picture',
        description: 'profilePicture',
    },
    required: {
        id: 'app.required',
        defaultMessage: '*Required',
        description: 'required error message',
    },
    emailRequired: {
        id: 'app.emailRequired',
        defaultMessage: 'Email is required',
        description: 'Email is required',
    },
    emailInvalid: {
        id: 'app.emailInValid',
        defaultMessage: 'Invalid Email Address',
        description: 'Invalid Email Address',
    },
    passwordRequired: {
        id: 'app.passwordRequired',
        defaultMessage: 'Password is required',
        description: 'Password is required',
    },
    passwordInvalid: {
        id: 'app.passwordInValid',
        defaultMessage: 'Your password must be at least 8 characters. Please try again',
        description: 'Invalid Password',
    },
    submitButton: {
        id: 'app.submitButton',
        defaultMessage: 'Submit',
        description: 'Submit',
    },
    cancelButton: {
        id: 'app.cancelButton',
        defaultMessage: 'Cancel',
        description: 'Cancel',
    },
    table: {
        id: 'app.table',
        defaultMessage: 'Table',
        description: 'Table',
    },
    form: {
        id: 'app.form',
        defaultMessage: 'Form',
        description: 'form',
    },
    siteAdmin: {
        id: 'app.siteAdmin',
        defaultMessage: 'Site Admin',
        description: 'siteAdmin',
    },
    usersMenu: {
        id: 'app.usersMenu',
        defaultMessage: 'Manage Users',
        description: 'usersMenu',
    },
    partnerMenu: {
        id: 'app.partnerMenu',
        defaultMessage: 'Manage Service Providers',
        description: 'partnerMenu',
    },
    categoryMenu: {
        id: 'app.categoryMenu',
        defaultMessage: 'Manage Categories',
        description: 'categoryMenu',
    },
    categorieslist: {
        id: 'app.categorieslist',
        defaultMessage: 'Categories',
        description: 'categoryMenu',
    },
    bookingsMenu: {
        id: 'app.bookingsMenu',
        defaultMessage: 'Manage Jobs',
        description: 'bookingsMenu',
    },
    categoryTitle: {
        id: 'app.categoryTitle',
        defaultMessage: 'Category Title',
        description: 'Category Title',
    },
    country: {
        id: 'app.country',
        defaultMessage: 'Country',
        description: 'Country Name'
    },
    createdAt: {
        id: 'app.createdAt',
        defaultMessage: 'Account Created on',
        description: 'Created date'
    },
    action: {
        id: 'app.action',
        defaultMessage: 'Action',
        description: 'Action'
    },
    basePrice: {
        id: 'app.basePrice',
        defaultMessage: 'Base Price',
        description: 'base price amount'
    },
    status: {
        id: 'app.status',
        defaultMessage: 'Status',
        description: 'Status Active/Inactive'
    },
    editAction: {
        id: 'app.editAction',
        defaultMessage: 'Edit',
        description: 'Edit action name'
    },
    deleteAction: {
        id: 'app.deleteAction',
        defaultMessage: 'Delete',
        description: 'Delete action name'
    },
    totalFare: {
        id: 'app.totalFare',
        defaultMessage: 'Total Fare',
        description: 'Total Fare'
    },
    promoCodeId: {
        id: 'app.promoCodeId',
        defaultMessage: 'Promo Code',
        description: 'Promo Code'
    },
    platformDiscount: {
        id: 'app.platformDiscount',
        defaultMessage: 'Platform offered discount',
        description: 'Special platformDiscount'
    },
    userDiscount: {
        id: 'app.userDiscount',
        defaultMessage: 'Coupon Discount',
        description: 'discount text'
    },
    userPayableAmount: {
        id: 'app.userPayableAmount',
        defaultMessage: 'User Payable Amount',
        description: 'userPayableAmount'
    },
    bookingStatus: {
        id: 'app.bookingStatus',
        defaultMessage: 'Job Status',
        description: 'Job Status'
    },
    userName: {
        id: 'app.userName',
        defaultMessage: 'User Name',
        description: 'User Name'
    },
    partnerName: {
        id: 'app.partnerName',
        defaultMessage: 'Service Provider Name',
        description: 'Service Provider Name'
    },
    userEmail: {
        id: 'app.userEmail',
        defaultMessage: 'User Email',
        description: 'User Email'
    },
    partnerEmail: {
        id: 'app.partnerEmail',
        defaultMessage: 'Service Provider Email',
        description: 'Service Provider Email'
    },
    bookingId: {
        id: 'app.bookingId',
        defaultMessage: 'Booking ID',
        description: 'Booking ID'
    },
    category: {
        id: 'app.category',
        defaultMessage: 'Category',
        description: 'Category'
    },
    view: {
        id: 'app.view',
        defaultMessage: 'View',
        description: 'View Details Link'
    },
    details: {
        id: 'app.details',
        defaultMessage: 'Details',
        description: 'Details'
    },
    userServiceFee: {
        id: 'app.userServiceFee',
        defaultMessage: 'User Service Fee',
        description: 'User Service Fee'
    },
    partnerServiceFee: {
        id: 'app.partnerServiceFee',
        defaultMessage: 'Service Provider Service Fee',
        description: 'Service Provider Service Fee'
    },
    categoryName: {
        id: 'app.categoryName',
        defaultMessage: 'Category Name',
        description: 'Category Name'
    },
    categoryLogo: {
        id: 'app.categoryLogo',
        defaultMessage: 'Logo',
        description: 'Category Logo'
    },
    categoryBanner: {
        id: 'app.categoryBanner',
        defaultMessage: 'Banner',
        description: 'Category Banner'
    },
    isPopular: {
        id: 'app.isPopular',
        defaultMessage: 'Is-Popular',
        description: 'Is-Popular'
    },
    isJobPhotoRequired: {
        id: 'app.isJobPhotoRequired',
        defaultMessage: 'Allow users to upload photos for the job',
        description: 'isJobPhotoRequired'
    },
    pricingType: {
        id: 'app.pricingType',
        defaultMessage: 'Pricing Type',
        description: 'Pricing Type'
    },
    update: {
        id: 'app.update',
        defaultMessage: 'Update',
        description: 'Update'
    },
    userStatus: {
        id: 'app.userStatus',
        defaultMessage: 'User Status',
        description: 'User Status Active/Inactive'
    },
    active: {
        id: 'app.active',
        defaultMessage: 'Active',
        description: 'Active Status'
    },
    inactive: {
        id: 'app.inactive',
        defaultMessage: 'Inactive',
        description: 'Inactive Status'
    },
    pending: {
        id: 'app.pending',
        defaultMessage: 'Pending',
        description: 'Pending Status'
    },
    banStatus: {
        id: 'app.banStatus',
        defaultMessage: 'Ban Status',
        description: 'Ban Status Permit/Ban'
    },
    permit: {
        id: 'app.permit',
        defaultMessage: 'Permit',
        description: 'Permit Status'
    },
    ban: {
        id: 'app.ban',
        defaultMessage: 'Ban',
        description: 'Ban Status'
    },
    phoneDialCode: {
        id: 'app.phoneDialCode',
        defaultMessage: 'Country Code',
        description: 'Phone Dial Code'
    },
    addCategory: {
        id: 'app.addCategory',
        defaultMessage: 'Add Category',
        description: 'Add category form header'
    },
    editUser: {
        id: 'app.editUser',
        defaultMessage: 'Edit User',
        description: 'Edit User form header'
    },
    editPartner: {
        id: 'app.editPartner',
        defaultMessage: 'Edit Service Provider',
        description: 'Edit Service Provider form header'
    },
    editCategory: {
        id: 'app.editCategory',
        defaultMessage: 'Edit Category',
        description: 'Edit category form header'
    },
    goBack: {
        id: 'app.goBack',
        defaultMessage: 'Go Back',
        description: 'Go Back Button Text'
    },
    floatError: {
        id: 'app.floatError',
        defaultMessage: 'It should be a number value',
        description: 'Numeric Error Response'
    },
    numericValue: {
        id: 'app.numericValue',
        defaultMessage: 'Numeric value required',
        description: 'Numeric value required'
    },
    validUrl: {
        id: 'app.validUrl',
        defaultMessage: 'Valid URL required',
        description: 'Valid URL required'
    },
    phoneError: {
        id: 'app.numberError',
        defaultMessage: 'Invalid Phone Number',
        description: 'Invalid Phone Number'
    },
    currency: {
        id: 'app.currency',
        defaultMessage: 'Currency',
        description: 'Currency Type'
    },
    manageDashboard: {
        id: 'app.manageDashboard',
        defaultMessage: 'Dashboard',
        description: 'Manage Dashboard'
    },
    chooseFile: {
        id: 'app.chooseFile',
        defaultMessage: 'Choose File',
        description: ''
    },
    maximumUploadSizeLabel: {
        id: 'app.maximumUploadSizeLabel',
        defaultMessage: 'Maximum upload size',
        description: ''
    },
    followUs: {
        id: 'app.followUs',
        defaultMessage: 'Follow  Us',
        description: 'Follow Us',
    },
    user: {
        id: 'app.user',
        defaultMessage: 'User',
        description: 'user',
    },
    partner: {
        id: 'app.partner',
        defaultMessage: 'Service Provider',
        description: 'partner',
    },
    partners: {
        id: 'app.partners',
        defaultMessage: 'Service Providers',
        description: 'partner',
    },
    today: {
        id: 'app.today',
        defaultMessage: 'Today',
        description: 'today',
    },
    weekly: {
        id: 'app.weekly',
        defaultMessage: 'This Week',
        description: 'weekly',
    },
    montly: {
        id: 'app.montly',
        defaultMessage: 'This Month',
        description: 'montly',
    },
    bookings: {
        id: 'app.bookings',
        defaultMessage: 'Bookings',
        description: 'bookings',
    },
    logout: {
        id: 'app.logout',
        defaultMessage: 'Logout',
        description: 'logout option',
    },
    goToMainSite: {
        id: 'app.goToMainSite',
        defaultMessage: 'Go to main site',
        description: 'goToMainSite option',
    },
    managePromoCode: {
        id: 'app.managePromoCode',
        defaultMessage: 'Manage Promo Code',
        description: 'managePromoCode sidemenu',
    },
    addPromoCode: {
        id: 'app.addPromoCode',
        defaultMessage: 'Add Promo Code',
        description: 'Add PromoCode',
    },
    editPromoCode: {
        id: 'app.editPromoCode',
        defaultMessage: 'Edit Promo Code',
        description: 'Edit PromoCode',
    },
    addPage: {
        id: 'app.addPage',
        defaultMessage: "Add Page",
        description: "Add Page"
    },
    id: {
        id: 'app.id',
        defaultMessage: 'ID',
        description: 'ID text',
    },
    title: {
        id: 'app.title',
        defaultMessage: 'Title',
        description: 'text',
    },
    description: {
        id: 'app.description',
        defaultMessage: 'Description',
        description: 'text',
    },
    code: {
        id: 'app.code',
        defaultMessage: 'Code',
        description: 'text',
    },
    discount: {
        id: 'app.discount',
        defaultMessage: 'Discount',
        description: 'text',
    },
    promoType: {
        id: 'app.promoType',
        defaultMessage: 'Type',
        description: 'text',
    },
    expireDate: {
        id: 'app.expireDate',
        defaultMessage: 'Expiry Date',
        description: 'text',
    },
    percentage: {
        id: 'app.percentage',
        defaultMessage: 'Percentage',
        description: 'text',
    },
    fixedAmount: {
        id: 'app.fixedAmount',
        defaultMessage: 'Fixed Amount',
        description: 'text',
    },
    manageCompletedBooking: {
        id: 'app.manageCompletedBooking',
        defaultMessage: 'Completed Jobs',
        description: 'CompletedBooking sidemenu',
    },
    manageCancelledBookings: {
        id: 'app.manageCancelledBookings',
        defaultMessage: 'Cancelled Jobs',
        description: 'manageCancelledBookings sidemenu',
    },
    manageCancelReason: {
        id: 'app.manageCancelReason',
        defaultMessage: 'Cancel Reason',
        description: 'Cancel Reason',
    },
    manageCurrency: {
        id: 'app.manageCurrency',
        defaultMessage: 'Manage Currency',
        description: 'manageCurrency sidemenu',
    },
    symbol: {
        id: 'app.symbol',
        defaultMessage: 'Symbol',
        description: 'currencyManagement value',
    },
    baseCurrency: {
        id: 'app.BaseCurrency',
        defaultMessage: 'Base Currency',
        description: 'currencyManagement value',
    },
    enableDisable: {
        id: 'app.enableDisable',
        defaultMessage: 'Set Enable / Disable',
        description: 'currencyManagement value',
    },
    setBaseCurrency: {
        id: 'app.setBaseCurrency',
        defaultMessage: 'Set Base Currency',
        description: 'currencyManagement value',
    },
    noResult: {
        id: 'app.noResult',
        defaultMessage: 'No results found',
        description: 'noResult'
    },
    addLocation: {
        id: 'app.addLocation',
        defaultMessage: 'Add Location',
        description: 'addLocation'
    },
    location: {
        id: 'app.location',
        defaultMessage: 'Manage Location',
        description: 'location'
    },
    edit: {
        id: 'app.edit',
        defaultMessage: 'Edit',
        description: 'edit'
    },
    managePayout: {
        id: 'app.managePayout',
        defaultMessage: 'Manage Payout',
        description: 'managePayout'
    },
    partnerPayout: {
        id: 'app.partnerPayout',
        defaultMessage: 'Service Provider Payout',
        description: 'managePayout'
    },
    amount: {
        id: 'app.Amount',
        defaultMessage: 'Amount',
        description: 'Amount'
    },
    payoutStatus: {
        id: 'app.payoutStatus',
        defaultMessage: 'Payout Status',
        description: 'Payout Status'
    },
    holdPayout: {
        id: 'app.holdPayout',
        defaultMessage: 'Hold Payout',
        description: 'Hold Payout'
    },
    yes: {
        id: 'app.yes',
        defaultMessage: 'Yes',
        description: 'Yes'
    },
    no: {
        id: 'app.no',
        defaultMessage: 'No',
        description: 'no'
    },
    homeSectionSettings: {
        id: 'app.homeSectionSettings',
        defaultMessage: 'Banner',
        description: 'Banner Settings'
    },
    homepageBanner: {
        id: 'app.homepageBanner',
        defaultMessage: 'Homepage Banner',
        description: 'Homepage Banner Settings'
    },
    aboutSectionSettings: {
        id: 'app.aboutSectionSettings',
        defaultMessage: 'Top Features',
        description: 'Top Features Settings'
    },
    citySectionSettings: {
        id: 'app.citySectionSettings',
        defaultMessage: 'Category',
        description: 'Category Settings'
    },
    safetySectionSettings: {
        id: 'app.safetySectionSettings',
        defaultMessage: 'User App',
        description: 'User Apps Settings'
    },
    signupSectionSettings: {
        id: 'app.signupSectionSettings',
        defaultMessage: 'Service Provider App',
        description: 'Service Provider Apps Settings'
    },
    footerSectionSettings: {
        id: 'app.footerSectionSettings',
        defaultMessage: 'Footer',
        description: 'Footer Settings'
    },
    manageFailedPayout: {
        id: 'app.manageFailedPayout',
        defaultMessage: 'Failed Payout',
        description: 'Failed Payout'
    },
    content: {
        id: 'app.content',
        defaultMessage: 'Content',
        description: 'Content 1'
    },
    aboutImage1: {
        id: 'app.aboutImage1',
        defaultMessage: 'Feature Icon #1',
        description: 'Feature Icon 1'
    },
    aboutImage2: {
        id: 'app.aboutImage2',
        defaultMessage: 'Feature Icon #2',
        description: 'Feature Icon 2'
    },
    homeImage1: {
        id: 'app.homeImage1',
        defaultMessage: 'Banner Image #1',
        description: 'Banner Image 1'
    },
    homeImage2: {
        id: 'app.homeImage2',
        defaultMessage: 'Banner Image #2',
        description: 'Banner Image 2'
    },
    homeTitle: {
        id: 'app.homeTitle',
        defaultMessage: 'Banner Title',
        description: 'Banner Title'
    },
    safetyImage1: {
        id: 'app.safetyImage1',
        defaultMessage: 'Playstore Logo',
        description: 'Playstore Logo'
    },
    safetyImage2: {
        id: 'app.safetyImage2',
        defaultMessage: 'Appstore Logo',
        description: 'Appstore Logo'
    },
    safetyImage3: {
        id: 'app.safetyImage3',
        defaultMessage: 'User App Image',
        description: 'User App Image'
    },
    signupImage1: {
        id: 'app.signupImage1',
        defaultMessage: 'Play Store Logo',
        description: 'Playstore Logo'
    },
    signupImage2: {
        id: 'app.signupImage2',
        defaultMessage: 'App Store Logo',
        description: 'Appstore Logo'
    },
    signupImage3: {
        id: 'app.signupImage3',
        defaultMessage: 'Service Provider App Image',
        description: 'Service provider App Image'
    },
    earnings: {
        id: 'app.earnings',
        defaultMessage: 'Your Earnings',
        description: 'Earnings'
    },
    overAll: {
        id: 'app.overAll',
        defaultMessage: 'Overall',
        description: 'Overall'
    },
    approve: {
        id: 'app.approve',
        defaultMessage: 'Approve',
        description: 'Approve'
    },
    decline: {
        id: 'app.decline',
        defaultMessage: 'Decline',
        description: 'Decline'
    },
    online: {
        id: 'app.online',
        defaultMessage: 'Online',
        description: 'online'
    },
    offline: {
        id: 'app.offline',
        defaultMessage: 'Offline',
        description: 'offline'
    },
    eligibleForAService: {
        id: 'app.eligibleForAService',
        defaultMessage: 'Eligible for a Service',
        description: 'text'
    },
    notEligibleForAService: {
        id: 'app.notEligibleForAService',
        defaultMessage: 'Not eligible for a Service',
        description: 'text'
    },
    preview: {
        id: 'app.preview',
        defaultMessage: 'Preview',
        description: 'Preview'
    },
    pageName: {
        id: 'app.pageName',
        defaultMessage: 'Page Name',
        description: 'Page Name'
    },
    staticpageManagement: {
        id: 'app.staticpageManagement',
        defaultMessage: 'Manage Static Content',
        description: 'Manage Static Content'
    },
    contentpageManagement: {
        id: 'app.contentpageManagement',
        defaultMessage: 'Content Management',
        description: 'Content Management'
    },
    editPageDetails: {
        id: 'app.editPageDetails',
        defaultMessage: 'Edit Page Details',
        description: 'Edit Page Details'
    },
    pageBanner: {
        id: 'app.pageBanner',
        defaultMessage: 'Page Banner',
        description: 'Page Banner'
    },
    pageImageBanner: {
        id: 'app.pageImageBanner',
        defaultMessage: 'Page Image Banner',
        description: 'Page Image Banner'
    },
    manageFare: {
        id: 'app.manageFare',
        defaultMessage: 'Manage Fare',
        description: 'Admin sidemenu'
    },
    locationName: {
        id: 'app.locationName',
        defaultMessage: 'Location',
        description: 'Table header'
    },
    addFare: {
        id: 'app.addFare',
        defaultMessage: 'Add Fare',
        description: 'Button '
    },
    editFare: {
        id: 'app.adeditFaredFare',
        defaultMessage: 'Edit Fare',
        description: 'Button '
    },
    paid: {
        id: 'app.paid',
        defaultMessage: 'Paid',
        description: 'Paid'
    },
    unpaid: {
        id: 'app.unpaid',
        defaultMessage: 'Unpaid',
        description: 'Unpaid'
    },
    payoutAmount: {
        id: 'app.payoutAmount',
        defaultMessage: 'Payout Amount',
        description: 'Payout Amount'
    },
    searchLocation: {
        id: 'app.searchLocation',
        defaultMessage: 'Search Location',
        description: 'Auto-Complete input'
    },
    removeLocation: {
        id: 'app.removeLocation',
        defaultMessage: 'Remove Location',
        description: 'Polygon remove'
    },
    walletBalance: {
        id: 'app.walletBalance',
        defaultMessage: 'Wallet Balance',
        description: 'Wallet Balance'
    },
    paymentMethod: {
        id: 'app.paymentMethod',
        defaultMessage: 'Payment Method',
        description: 'Payment Method'
    },
    card: {
        id: 'app.card',
        defaultMessage: 'Card',
        description: 'Card'
    },
    cash: {
        id: 'app.cash',
        defaultMessage: 'Cash',
        description: 'Cash'
    },
    wallet: {
        id: 'app.wallet',
        defaultMessage: 'Wallet',
        description: 'Wallet'
    },
    exportDataIntoCSV: {
        id: 'app.exportDataIntoCSV',
        defaultMessage: 'Export Data into CSV',
        description: 'Export Data into CSV',
    },
    roleName: {
        id: 'app.roleName',
        defaultMessage: 'Role Name',
        description: 'Role Name',
    },
    privileges: {
        id: 'app.privileges',
        defaultMessage: 'Privileges',
        description: 'Privileges',
    },
    emailText: {
        id: 'app.emailText',
        defaultMessage: 'Email',
        description: 'Email',
    },
    adminRole: {
        id: 'app.adminRole',
        defaultMessage: 'Admin Role',
        description: 'Admin Role',
    },
    adminName: {
        id: 'app.adminName',
        defaultMessage: 'Name',
        description: 'Name'
    },
    passwordText: {
        id: 'app.passwordText',
        defaultMessage: 'Provide new password only. Ignore this field to use the existing password for this user.',
        description: 'Password Content',
    },
    selectRole: {
        id: 'app.selectRole',
        defaultMessage: 'Select Role',
        description: 'Select Role',
    },
    role: {
        id: 'app.role',
        defaultMessage: 'Role',
        description: 'Role',
    },
    manageAdmin: {
        id: 'app.manageAdmin',
        defaultMessage: 'Manage Admins',
        description: 'Manage Admins',
    },
    manageAdminUsers: {
        id: 'app.manageAdminUsers',
        defaultMessage: 'Manage Admin Users',
        description: 'Manage Admin Users',
    },
    manageAdminRoles: {
        id: 'app.manageAdminRoles',
        defaultMessage: 'Manage Admin Roles',
        description: 'Manage Admin Roles',
    },
    addNewLabel: {
        id: 'app.addNewLabel',
        defaultMessage: 'Add New',
        description: 'Add New',
    },
    chooseLanguageLabel: {
        id: 'app.chooseLanguageLabel',
        defaultMessage: 'Choose a language',
        description: 'Choose a language',
    },
    askQuestion: {
        id: 'app.askQuestion',
        defaultMessage: 'Ask a question',
        description: 'Ban Status'
    },
    reportIssue: {
        id: 'app.reportIssue',
        defaultMessage: 'Report an issue',
        description: 'Ban Status'
    },
    Enabled: {
        id: 'app.Enabled',
        defaultMessage: 'Enabled',
        description: 'Ban Status'
    },
    Disabled: {
        id: 'app.Disabled',
        defaultMessage: 'Disabled',
        description: 'Ban Status'
    },
    Enable: {
        id: 'app.Enable',
        defaultMessage: 'Enable',
        description: 'Ban Status'
    },
    Disable: {
        id: 'app.Disable',
        defaultMessage: 'Disable',
        description: 'Ban Status'
    },
    setBase: {
        id: 'app.setBase',
        defaultMessage: 'Set as base currency',
        description: 'Ban Status'
    },
    Support: {
        id: 'app.Support',
        defaultMessage: 'Support',
        description: 'Ban Status'
    },
    notFound: {
        id: 'app.notFound',
        defaultMessage: 'Sorry, the page you were trying to view does not exist.',
        description: 'Ban Status'
    },
    oneOne: {
        id: 'app.oneOne',
        defaultMessage: 'One',
        description: 'Ban Status'
    },
    currencies: {
        id: 'app.currencies',
        defaultMessage: 'Currencies',
        description: 'Ban Status'
    },
    fares: {
        id: 'app.fares',
        defaultMessage: 'Fares',
        description: 'Fares'
    },
    homeonly: {
        id: 'app.homeonly',
        defaultMessage: 'Home',
        description: 'Fares'
    },
    allonly: {
        id: 'app.allonly',
        defaultMessage: 'All',
        description: 'Fares'
    },
    playstorelink: {
        id: 'app.playstorelink',
        defaultMessage: 'Play Store Link',
        description: 'Fares'
    },
    appstorelink: {
        id: 'app.appstorelink',
        defaultMessage: 'App Store Link',
        description: 'Fares'
    },
    partnerappcntn: {
        id: 'app.partnerappcntn',
        defaultMessage: 'Service Provider App Content',
        description: 'Fares'
    },
    partnerapptitle: {
        id: 'app.partnerapptitle',
        defaultMessage: 'Service Provider App Title',
        description: 'Fares'
    },
    logowidth: {
        id: 'app.logowidth',
        defaultMessage: 'Logo Width',
        description: 'Fares'
    },
    logoheight: {
        id: 'app.logoheight',
        defaultMessage: 'Logo Height',
        description: 'Fares'
    },
    processedCash: {
        id: 'app.processedCash',
        defaultMessage: 'Processed via cash',
        description: 'Label'
    },
    completedLabel: {
        id: 'app.completedLabel',
        defaultMessage: 'Completed',
        description: 'Label'
    },
    pendingLabel: {
        id: 'app.pendingLabel',
        defaultMessage: 'Pending',
        description: 'Label'
    },
    siteName: {
        id: 'app.siteName',
        defaultMessage: 'Site Name',
        description: 'Fares'
    },
    siteTitle: {
        id: 'app.siteTitle',
        defaultMessage: 'Site Title',
        description: 'Fares'
    },
    metakey: {
        id: 'app.metakey',
        defaultMessage: 'Meta Keyword',
        description: 'Fares'
    },
    metaDesc: {
        id: 'app.metaDesc',
        defaultMessage: 'Meta Description',
        description: 'Fares'
    },
    errorOnly: {
        id: 'app.errorOnly',
        defaultMessage: 'Error',
        description: 'Fares'
    },
    credentialError: {
        id: 'app.credentialError',
        defaultMessage: 'Sorry, a critical error occurred on this page.',
        description: 'Fares'
    },
    addOnly: {
        id: 'app.addOnly',
        defaultMessage: 'Address',
        description: 'Fares'
    },
    state: {
        id: 'app.state',
        defaultMessage: 'State',
        description: 'Fares'
    },
    checkmein: {
        id: 'app.checkmein',
        defaultMessage: 'Check me in',
        description: 'Fares'
    },
    checkmeout: {
        id: 'app.checkmeout',
        defaultMessage: 'Check me out',
        description: 'Fares'
    },
    tableHead: {
        id: 'app.tableHead',
        defaultMessage: 'Table heading',
        description: 'Fares'
    },
    tableCell: {
        id: 'app.tableCell',
        defaultMessage: 'Table cell',
        description: 'Fares'
    },
    confirmPassword: {
        id: 'app.confirmPassword',
        defaultMessage: 'Confirm Password',
        description: 'Change Password'
    },
    nameOnly: {
        id: 'app.nameOnly',
        defaultMessage: 'Name',
        description: 'Change Password'
    },
    msgOnly: {
        id: 'app.msgOnly',
        defaultMessage: 'Message',
        description: 'Change Password'
    },
    featureTitle1: {
        id: 'app.featureTitle1',
        defaultMessage: 'Feature Title #1',
        description: 'Change Password'
    },
    featureDesc1: {
        id: 'app.featureDesc1',
        defaultMessage: 'Feature Description #1',
        description: 'Change Password'
    },
    footerTitle1: {
        id: 'app.footerTitle1',
        defaultMessage: 'Footer Title #1',
        description: 'Change Password'
    },
    footerContent1: {
        id: 'app.footerContent1',
        defaultMessage: 'Footer Content #1',
        description: 'Change Password'
    },
    footerUrltitle: {
        id: 'app.footerUrltitle',
        defaultMessage: 'Footer URL Title',
        description: 'Change Password'
    },
    copyRightcontent: {
        id: 'app.copyRightcontent',
        defaultMessage: 'Copyrights content',
        description: 'Change Password'
    },
    radioButton: {
        id: 'app.radioButton',
        defaultMessage: 'Radio button',
        description: 'Change Password'
    },
    userApptitle: {
        id: 'app.userApptitle',
        defaultMessage: 'User App Title',
        description: 'Change Password'
    },
    userAppContent: {
        id: 'app.userAppContent',
        defaultMessage: 'User App Content',
        description: 'Change Password'
    },
    leaveEmail: {
        id: 'app.leaveEmail',
        defaultMessage: 'Leave email address field blank if you don\'t want to change the email',
        description: 'Change Password'
    },
    searchOnly: {
        id: 'app.searchOnly',
        defaultMessage: 'Search',
        description: 'Change Password'
    },
    add: {
        id: 'app.add',
        defaultMessage: 'Add',
        description: 'Add Button',
    },
    topFeatures: {
        id: 'app.topFeatures',
        defaultMessage: 'Top Features Section Settings',
        description: 'Top Features Section Settings',
    },
    managePartnerPayout: {
        id: 'app.managePartnerPayout',
        defaultMessage: 'Manage Service Provider Payouts',
        description: 'Manage Service Provider Payouts'
    },
    changeAdminPassword: {
        id: 'app.changeAdminPassword',
        defaultMessage: 'Change Admin Email/Password',
        description: 'Change Admin Email/Password'
    },
    currencyList: {
        id: 'app.currencyList',
        defaultMessage: 'Manage Currency',
        description: 'Manage Currency'
    },
    editLocation: {
        id: 'app.editLocation',
        defaultMessage: 'Edit Location',
        description: 'Edit Location'
    },
    bookingDetails: {
        id: 'app.bookingDetails',
        defaultMessage: 'Booking Details',
        description: 'Booking Details'
    },
    userAndriodVersion: {
        id: 'app.userAndriodVersion',
        defaultMessage: 'User Android Version',
        description: 'userAndriodVersion'
    },
    useriosVersion: {
        id: 'app.useriosVersion',
        defaultMessage: 'User iOS Version',
        description: 'useriosVersion'
    },
    partnerAndriodVersion: {
        id: 'app.partnerAndriodVersion',
        defaultMessage: 'Service Provider Android Version',
        description: 'Andriod'
    },
    partneriosVersion: {
        id: 'app.partneriosVersion',
        defaultMessage: 'Service Provider iOS Version',
        description: 'iOS'
    },
    forceUpdate: {
        id: 'app.forceUpdate',
        defaultMessage: 'Force update',
        description: 'forceUpdate'
    },
    invalidVersionNumber: {
        id: 'app.invalidVersionNumber',
        defaultMessage: 'Invalid Version Number',
        description: 'invalidVersionNumber'
    },
    to: {
        id: 'app.to',
        defaultMessage: 'To',
        description: 'Label'
    },
    validatePasswordLength: {
        id: 'app.validatePasswordLength',
        defaultMessage: 'Password must be minimum 8 characters',
        description: 'Password length Validation'
    },
    validateConfirmPasswordLength: {
        id: 'app.validateConfirmPasswordLength',
        defaultMessage: 'Confirm Password must be minimum 8 characters',
        description: 'Confirm Password length Validation'
    },
    validatePasswordMatch: {
        id: 'app.validatePasswordMatch',
        defaultMessage: 'Password is mismatching with Confirm password',
        description: 'Password mismatch'
    },
    itemTotal: {
        id: 'app.itemTotal',
        defaultMessage: 'Item Total',
        description: 'Label'
    },
    userBillingInformation: {
        id: 'app.userBillingInformation',
        defaultMessage: 'User Billing Information',
        description: 'Label'
    },
    partnerBillingInformation: {
        id: 'app.partnerBillingInformation',
        defaultMessage: 'Service Provider Billing Information',
        description: 'Label'
    },
    tipsReceivedFromUser: {
        id: 'app.tipsReceivedFromUser',
        defaultMessage: 'Tips received from the user',
        description: 'Label'
    },
    tipsGivenToPartner: {
        id: 'app.tipsGivenToPartner',
        defaultMessage: 'Tips given to the service provider',
        description: 'Label'
    },
    platformEarnings: {
        id: 'app.platformEarnings',
        defaultMessage: 'Platform Earnings',
        description: 'Label'
    },
    platformOffered: {
        id: 'app.platformOffered',
        defaultMessage: 'Platform Overdrafts',
        description: 'Label'
    },
    addAdminRole: {
        id: 'app.addAdminRole',
        defaultMessage: 'Add Admin Role',
        description: 'Title'
    },
    editAdminRole: {
        id: 'app.editAdminRole',
        defaultMessage: 'Edit Admin Role',
        description: 'Title'
    },
    locations: {
        id: 'app.locations',
        defaultMessage: 'Locations',
        description: 'Pagination'
    },
    addAdminUserLabel: {
        id: 'app.addAdminUserLabel',
        defaultMessage: 'Add Admin User',
        description: 'Add Admin User'
    },
    editAdminUserLabel: {
        id: 'app.editAdminUserLabel',
        defaultMessage: 'Edit Admin User',
        description: 'Edit Admin User'
    },
    completed: {
        id: 'app.completed',
        defaultMessage: 'Completed',
        description: 'Completed Status'
    },
    approved: {
        id: 'app.approved',
        defaultMessage: 'Approved',
        description: 'Approved Status'
    },
    declined: {
        id: 'app.declined',
        defaultMessage: 'Declined',
        description: 'Declined Status'
    },
    started: {
        id: 'app.started',
        defaultMessage: 'Started',
        description: 'Started Status'
    },
    cancelledByUser: {
        id: 'app.cancelledByUser',
        defaultMessage: 'Cancelled By User',
        description: 'Cancelled By User Status'
    },
    cancelledByPartner: {
        id: 'app.cancelledByPartner',
        defaultMessage: 'Cancelled By Service Provider',
        description: 'Cancelled By Service Provider Status'
    },
    expired: {
        id: 'app.expired',
        defaultMessage: 'Expired',
        description: 'Expired Status'
    },
    notRequiredMessage: {
        id: 'app.notRequiredMessage',
        defaultMessage: 'Not Required',
        description: 'Not Required'
    },
    partnerPrivacyPolicy: {
        id: 'app.partnerPrivacyPolicy',
        defaultMessage: 'Service Provider Privacy Policy',
        description: 'Title',
    },
    precautionNotification: {
        id: 'app.precautionNotification',
        defaultMessage: 'Precaution Notification',
        description: 'Title',
    },
    image: {
        id: 'app.image',
        defaultMessage: 'Image',
        description: 'Label',
    },
    updatedAt: {
        id: 'app.updatedAt',
        defaultMessage: 'Updated At',
        description: 'Label'
    },
    chatLabel: {
        id: 'app.chatLabel',
        defaultMessage: 'Chat',
        description: 'Chat'
    },
    chatMessageLabel: {
        id: 'app.chatMessageLabel',
        defaultMessage: 'Chat Message',
        description: 'Chat Message'
    },
    stripePublishableKey: {
        id: 'app.stripePublishableKey',
        defaultMessage: 'Stripe Publishable Key',
        description: 'Stripe Publishable Key'
    },
    loadMoreMsg: {
        id: 'app.loadMoreMsg',
        defaultMessage: 'Load More',
        description: 'Load more button',
    },
    /**Loaded dynamically in View booking */
    km: {
        id: 'app.km',
        defaultMessage: 'Km',
        description: 'Km',
    },
    mile: {
        id: 'app.mile',
        defaultMessage: 'Mile',
        description: 'Mile',
    },
    /**Loaded dynamically in View booking */
    tracking: {
        id: 'app.tracking',
        defaultMessage: 'Tracking',
        description: 'tracking',
    },
    mapView: {
        id: 'app.mapView',
        defaultMessage: 'Map View',
        description: 'mapView',
    },
    heatMap: {
        id: 'app.heatMap',
        defaultMessage: 'HeatMap',
        description: 'heatMap',
    },
    all: {
        id: 'app.all',
        defaultMessage: 'All',
        description: 'all',
    },
    availablePartners: {
        id: 'app.availablePartners',
        defaultMessage: 'Available Service Providers',
        description: 'availablePartners',
    },
    unAvailablePartners: {
        id: 'app.unAvailablePartners',
        defaultMessage: 'Unavailable Service Providers',
        description: 'unAvailablePartners',
    },
    unActivatedPartners: {
        id: 'app.unActivatedPartners',
        defaultMessage: 'Unactivated Service Providers',
        description: 'unActivatedPartners',
    },
    mobileSettings: {
        id: 'app.mobileSettings',
        defaultMessage: 'Mobile App Settings',
        description: 'Mobile App Settings'
    },
    notificationInterval: {
        id: 'app.notificationInterval',
        defaultMessage: 'Job acceptance time for the service provider',
        description: 'notificationInterval'
    },
    sleepPartnerAndroid: {
        id: 'app.sleepPartnerAndroid',
        defaultMessage: 'Service Provider Android App',
        description: 'sleepPartnerAndroid'
    },
    sleepPartnerios: {
        id: 'app.sleepPartnerios',
        defaultMessage: 'Service Provider iOS App ',
        description: 'sleepPartnerios'
    },
    show: {
        id: 'app.show',
        defaultMessage: 'Show',
        description: 'show'
    },
    hide: {
        id: 'app.hide',
        defaultMessage: 'Hide',
        description: 'hide'
    },
    jobRequestWindow: {
        id: 'app.jobRequestWindow',
        defaultMessage: "Manage service provider app's job request window information",
        description: 'jobRequestWindow'
    },
    sleepMode: {
        id: 'app.sleepMode',
        defaultMessage: 'Manage Service Provider App Sleep Mode',
        description: 'sleepMode'
    },
    stripeSettings: {
        id: 'app.stripeSettings',
        defaultMessage: 'Stripe account settings',
        description: 'stripeSettings'
    },
    manageForceUpdate: {
        id: 'app.manageForceUpdate',
        defaultMessage: 'Manage Apps Force Update',
        description: 'manageForceUpdate'
    },
    allowableDistaceTitle: {
        id: 'app.allowableDistaceTitle',
        defaultMessage: 'Maximum distance to cover while fetching service providers',
        description: 'allowableDistaceTitle'
    },
    allowedServices: {
        id: 'app.allowedServices',
        defaultMessage: 'Maximum sub-categories that the users can select while requesting a job',
        description: 'allowedServices'
    },
    invalid: {
        id: 'app.invalid',
        defaultMessage: 'Invalid',
        description: 'invalid'
    },
    markerInfo: {
        id: 'app.markerInfo',
        defaultMessage: 'Marker Info',
        description: 'Marker Info'
    },
    favIconlogoLabel: {
        id: 'app.favIconlogoLabel',
        defaultMessage: 'Fav Icon',
        description: 'Fav Icon'
    },
    contactPhoneNumber: {
        id: 'app.contactPhoneNumber',
        defaultMessage: 'Contact phone number',
        description: 'contactPhoneNumber'
    },
    contactEmail: {
        id: 'app.contactEmail',
        defaultMessage: 'Contact email',
        description: 'contactEmail'
    },
    skype: {
        id: 'app.skype',
        defaultMessage: 'Skype',
        description: 'skype'
    },
    last7Days: {
        id: 'app.last7Days',
        defaultMessage: 'Last 7 days',
        description: 'select label'
    },
    allDays: {
        id: 'app.allDays',
        defaultMessage: 'All days',
        description: 'select label'
    },
    last30Days: {
        id: 'app.last30Days',
        defaultMessage: 'Last 30 days',
        description: 'select label'
    },
    addCurrency: {
        id: 'app.addCurrency',
        defaultMessage: 'Add currency',
        description: 'addCurrency'
    },
    legal: {
        id: 'app.legal',
        defaultMessage: 'Legal',
        description: 'legal',
    },
    fixedFareType: {
        id: 'app.fixedFareType',
        defaultMessage: 'Fixed fare Type',
        description: 'Fixed fare Type'
    },
    hourlyFareType: {
        id: 'app.hourlyFareType',
        defaultMessage: 'Hourly fare type',
        description: 'Hourly fare type'
    },
    subCategoryMenu: {
        id: 'app.subCategoryMenu',
        defaultMessage: 'Manage Sub Category',
        description: 'Manage SubCategory'
    },
    name: {
        id: 'app.name',
        defaultMessage: 'Name',
        description: 'name',
    },
    addSubCategory: {
        id: 'app.addSubCategory',
        defaultMessage: 'Add Sub Category',
        description: 'Add subcategory form header'
    },
    editSubCategory: {
        id: 'app.editSubCategory',
        defaultMessage: 'Edit Sub Category',
        description: 'Edit subcategory form header'
    },
    isPriceEditable: {
        id: 'app.isPriceEditable',
        defaultMessage: 'Can be editable by service provider?',
        description: 'Can be editable by service provider?',
    },
    hourlyPrice: {
        id: 'app.hourlyPrice',
        defaultMessage: 'Hourly Price',
        description: 'Hourly Price'
    },
    maxQuantity: {
        id: 'app.maxQuantity',
        defaultMessage: 'Maximum Quantity ',
        description: 'Maximum Quantity ',
    },
    minHours: {
        id: 'app.minHours',
        defaultMessage: 'Minimum Hours',
        description: 'Minimum Hours',
    },
    travelCharge: {
        id: 'app.travelCharge',
        defaultMessage: 'Visit Fee',
        description: 'Travelling Charge',
    },
    subCategory: {
        id: 'app.subCategory',
        defaultMessage: 'Sub Category',
        description: 'SubCategory'
    },
    subCategoryName: {
        id: 'app.subCategoryName',
        defaultMessage: 'Sub Category Name',
        description: 'subCategoryName'
    },
    backGroundImage: {
        id: 'app.subCatbackGroundImageegoryName',
        defaultMessage: 'Background Image',
        description: 'backGroundImage'
    },
    maximumEmergencyContact: {
        id: 'app.maximumEmergencyContact',
        defaultMessage: 'Maximum Emergency Contact',
        description: 'maximumEmergencyContact'
    },
    promoCodeImage: {
        id: 'app.promoCodeImage',
        defaultMessage: 'Promo Code Image',
        description: 'title'
    },
    userIdentity: {
        id: 'app.userIdentity',
        defaultMessage: 'User Identity',
        description: 'userIdentity'
    },
    userExperience: {
        id: 'app.userExperience',
        defaultMessage: 'User Experience',
        description: 'userExperience'
    },
    welcomeAdminLabel: {
        id: 'app.welcomeAdminLabel',
        defaultMessage: 'Welcome Admin !!!',
        description: 'Welcome Admin !!!',
    },
    duration: {
        id: 'app.duration',
        defaultMessage: 'Job acceptance time',
        description: 'duration'
    },
    job: {
        id: 'app.job',
        defaultMessage: 'Job list',
        description: 'job'
    },
    jobDescription: {
        id: 'app.jobDescription',
        defaultMessage: 'Job description',
        description: 'jobDescription'
    },
    jobPhoto: {
        id: 'app.jobPhoto',
        defaultMessage: 'Job photos',
        description: 'hide'
    },
    estimatedPrice: {
        id: 'app.estimatedPrice',
        defaultMessage: 'Estimated price',
        description: 'estimatedPrice'
    },
    jobLocation: {
        id: 'app.jobLocation',
        defaultMessage: 'Job location',
        description: 'jobLocation'
    },
    validateInterval: {
        id: 'app.validateInterval',
        defaultMessage: 'Notification interval must be greater than 0.5 min',
        description: 'validateInterval'
    },
    userNumber: {
        id: 'app.userNumber',
        defaultMessage: 'User Phone Number',
        description: 'User Number'
    },
    partnerNumber: {
        id: 'app.partnerNumber',
        defaultMessage: 'Service Provider Phone Number',
        description: 'Service Provider Number'
    },
    additionalFee: {
        id: 'app.additionalFee',
        defaultMessage: 'Additional Charge',
        description: 'Additional Charge',
    },
    serviceFee: {
        id: 'app.serviceFee',
        defaultMessage: 'Service Fee',
        description: 'serviceFee ',
    },
    earningsLabel: {
        id: 'app.earningsLabel',
        defaultMessage: 'Earnings',
        description: 'Earnings'
    },
    jobProgress: {
        id: 'app.jobProgress',
        defaultMessage: 'Job Progress',
        description: 'jobProgress'
    },
    jobDetails: {
        id: 'app.jobDetails',
        defaultMessage: 'Job Details',
        description: 'jobDetails'
    },
    totalWorkingHours: {
        id: 'app.totalWorkingHours',
        defaultMessage: 'Total working hours =',
        description: 'Total working hours '
    },
    requestCreated: {
        id: 'app.requestCreated',
        defaultMessage: 'Request Created',
        description: 'Request Created'
    },
    requestAccepted: {
        id: 'app.requestAccepted',
        defaultMessage: 'Request Accepted',
        description: 'Request Accepted'
    },
    arrived: {
        id: 'app.arrived',
        defaultMessage: 'Arrived',
        description: 'Arrived'
    },
    requestReviewed: {
        id: 'app.requestReviewed',
        defaultMessage: 'Request Reviewed',
        description: 'Request Reviewed'
    },
    requestCancelled: {
        id: 'app.requestCancelled',
        defaultMessage: 'Request Cancelled',
        description: 'Request Cancelled'
    },
    jobStarted: {
        id: 'app.jobStarted',
        defaultMessage: 'Job Started',
        description: 'Job Started'
    },
    jobCompleted: {
        id: 'app.jobCompleted',
        defaultMessage: 'Job Completed',
        description: 'Job Completed'
    },
    totalEarnings: {
        id: 'app.totalEarnings',
        defaultMessage: 'Total Earnings',
        description: 'Earnings'
    },
    requestDecline: {
        id: 'app.requestDecline',
        defaultMessage: 'Request Declined',
        description: 'Request Declined'
    },
    subCategories: {
        id: 'app.subCategories',
        defaultMessage: 'Sub Categories',
        description: 'subCategories'
    },
    viewJob: {
        id: 'app.viewJob',
        defaultMessage: 'View job',
        description: 'view Job',
    },
    jobId: {
        id: 'app.jobId',
        defaultMessage: 'Job ID',
        description: 'Job',
    },
    requestTone: {
        id: 'app.requestTone',
        defaultMessage: 'Request accept timer tone',
        description: 'requestTone'
    },
    uploadRequestTone: {
        id: 'app.uploadRequestTone',
        defaultMessage: 'Upload timer tone',
        description: 'uploadRequestTone'
    },
    appOpenRequest: {
        id: 'app.appOpenTrip',
        defaultMessage: 'Open the provider app when a request notification is received',
        description: 'appOpenTrip'
    },
    jobs: {
        id: 'app.jobs',
        defaultMessage: 'Jobs',
        description: 'jobs',
    },
    manageSmsMethod: {
        id: 'app.manageSmsMethod',
        defaultMessage: 'Manage SMS Methods',
        description: 'manageSmsMethod',
    },
    accountId: {
        id: 'app.accountId',
        defaultMessage: 'ACCOUNT SID',
        description: 'ACCOUNT_SID'
    },
    secretId: {
        id: 'app.secretId',
        defaultMessage: 'AUTHTOKEN',
        description: 'AUTHTOKEN'
    },
    apiKey: {
        id: 'app.apiKey',
        defaultMessage: 'API KEY',
        description: 'API KEY'
    },
    secretKey: {
        id: 'app.secretKey',
        defaultMessage: 'SECRET KEY',
        description: 'SECRET KEY'
    },
    noLimit: {
        id: 'app.noLimit',
        defaultMessage: 'No Limit',
        description: 'noLimit'
    },
    records: {
        id: 'app.records',
        defaultMessage: 'Records per page',
        description: 'records'
    },
    editSmsMethod: {
        id: 'app.editSmsMethod',
        defaultMessage: 'Edit SMS Methods',
        description: 'editSmsMethod',
    },
    receipt: {
        id: 'app.receipt',
        defaultMessage: 'Receipt',
        description: 'receipt'
    },
    manageSchedule: {
        id: 'app.manageSchedule',
        defaultMessage: 'Manage Schedule Booking',
        description: 'manageSchedule'
    },
    scheduledFrom: {
        id: 'app.scheduledFrom',
        defaultMessage: 'Scheduled From',
        description: 'Label'
    },
    scheduledTo: {
        id: 'app.scheduledTo',
        defaultMessage: 'Scheduled To',
        description: 'Label'
    },
    scheduleBookingHistory: {
        id: 'app.scheduleBookingHistory',
        defaultMessage: 'Schedule Booking History',
        description: 'Heading'
    },
    validateScheduleInterval: {
        id: 'app.validateScheduleInterval',
        defaultMessage: 'Schedule interval must be greater than 20 min',
        description: 'validateScheduleInterval'
    },
    defaultScheduleInterval: {
        id: 'app.defaultScheduleInterval',
        defaultMessage: 'Default Schedule Interval',
        description: 'defaultScheduleInterval'
    },
    requestScheduled: {
        id: 'app.requestScheduled',
        defaultMessage: 'Request Scheduled',
        description: 'Request Scheduled'
    },
    minLableText: {
        id: 'app.minLableText',
        defaultMessage: 'min',
        description: 'min'
    },
    lightMode: {
        id: 'app.lightMode',
        defaultMessage: 'Light mode',
        description: 'Light mode',
    },
    darkMode: {
        id: 'app.darkMode',
        defaultMessage: 'Dark mode',
        description: 'Dark mode',
    },
    additionalWorkInterval: {
        id: 'app.additionalWorkInterval',
        defaultMessage: 'Additional work request acceptance time',
        description: 'additionalWorkInterval'
    },
    missedEarnings: {
        id: 'app.missedEarnings',
        defaultMessage: 'Missed earnings',
        description: 'missedEarnings'
    },
    smtpHost: {
        id: 'app.smtpHost',
        defaultMessage: 'SMTP host',
        description: 'smtpHost',
    },
    smtpPort: {
        id: 'app.smtpPort',
        defaultMessage: 'SMTP port',
        description: 'smtpPort',
    },
    smptEmail: {
        id: 'app.smptEmail',
        defaultMessage: 'SMTP email',
        description: 'smptEmail',
    },
    smtpSender: {
        id: 'app.smtpSender',
        defaultMessage: 'SMTP sender',
        description: 'smtpSender',
    },
    smtpSenderEmail: {
        id: 'app.smtpSenderEmail',
        defaultMessage: 'SMTP sender email',
        description: 'smtpSenderEmail',
    },
    smtpPassWord: {
        id: 'app.smtpPassWord',
        defaultMessage: 'SMTP password',
        description: 'smtpPassWord',
    },
    maxUploadLabel: {
        id: 'app.maxUploadLabel',
        defaultMessage: 'Maximum image upload size [MB/KB]',
        description: 'maxUploadLabel',
    },
    manageSiteConfig: {
        id: 'app.manageSiteConfig',
        defaultMessage: 'Manage site configuration',
        description: 'manageSiteConfig',
    },
    smtpSettings: {
        id: 'app.smtpSettings',
        defaultMessage: 'SMTP settings',
        description: 'smtpSettings'
    },
    pushNotificationSettings: {
        id: 'app.pushNotificationSettings',
        defaultMessage: 'Push notification settings',
        description: 'pushNotificationSettings'
    },
    otherSettings: {
        id: 'app.otherSettings',
        defaultMessage: 'Other settings',
        description: 'manageConfigHeading',
    },
    managePaymentGateWay: {
        id: 'app.managePaymentGateWay',
        defaultMessage: 'Manage payment gateway',
        description: 'Manage Payment Gateway',
    },
    paymentGateWay: {
        id: 'app.paymentGateWay',
        defaultMessage: 'Payment gateway',
        description: 'Payment Gateway',
    },
    googlePay: {
        id: 'app.googlePay',
        defaultMessage: 'GooglePay',
        description: 'GooglePay',
    },
    fcmPushNotificationKey: {
        id: 'app.fcmPushNotificationKey',
        defaultMessage: 'FCM push notification key',
        description: 'fcmPushNotificationKey'
    },
    bookingMethods: {
        id: 'app.bookingMethods',
        defaultMessage: 'Booking methods',
        description: 'Mobile App Settings'
    },
    randomProviderSelection: {
        id: 'app.randomProviderSelection',
        defaultMessage: 'Random provider selection',
        description: 'Mobile App Settings'
    },
    preferredProviderSelection: {
        id: 'app.preferredProviderSelection',
        defaultMessage: 'Preferred provider selection',
        description: 'Mobile App Settings'
    },
    randomProviderSelectionTooltip: {
        id: 'app.randomProviderSelectionTooltip',
        defaultMessage: 'Allow the system to choose the available nearby service providers for the user\'s needs.',
        description: 'Mobile App Settings'
    },
    preferredProviderSelectionTooltip: {
        id: 'app.preferredProviderSelectionTooltip',
        defaultMessage: 'Allow the user to choose the preferred service provider from the nearby available service providers list and connect with them for their needs.',
        description: 'Mobile App Settings'
    },
    preferredServiceProviderCount: {
        id: 'app.preferredServiceProviderCount',
        defaultMessage: 'Preferred service provider count',
        description: 'preferredServiceProviderCount'
    },
    senderId: {
        id: 'app.senderId',
        defaultMessage: 'SENDER ID',
        description: 'SENDER_ID'
    },
    delete: {
        id: 'app.delete',
        defaultMessage: 'Delete',
        description: 'Delete Button',
    },
    addNew: {
        id: 'app.addNew',
        defaultMessage: 'Name',
        description: 'Name',
    },
    addNewDescription: {
        id: 'app.addNewDescription',
        defaultMessage: 'Description',
        description: 'description',
    },
    exceedCharLimit: {
        id: 'app.exceedCharLimit',
        defaultMessage: 'Maximum allowed character is 255.',
        description: 'exceedLimit',
    },
    emailBlankSpace: {
        id: 'app.emailBlankSpace',
        defaultMessage: 'Email should not be blank spaces',
        description: 'email address error',
    },
    passwordInvalid1: {
        id: 'app.passwordInvalid1',
        defaultMessage: 'Password should be minimum 8 characters.',
        description: 'Password minimum characters error',
    },
    passwordBlankSpace: {
        id: 'app.blankSpace',
        defaultMessage: 'Password should not be blank space.',
        description: 'Password error',
    },
    email: {
        id: 'app.email',
        defaultMessage: 'Email Address',
        description: 'Your Email Address to Login',
    },
    emailNotExists: {
        id: 'app.emailNotExists',
        defaultMessage: 'No account exists for this email. Make sure it\'s typed in correctly, or “sign up” instead',
        description: 'Email Address is not exist',
    },
    passwordWrong: {
        id: 'app.passwordWrong',
        defaultMessage: 'Wrong Password. Please try again',
        description: 'Wrong Password',
    },
    somethingWentWrong: {
        id: 'app.somethingWentWrong',
        defaultMessage: 'Sorry, something went wrong. Please try again',
        description: 'Wrong went wrong',
    },
    loggedIn: {
        id: 'app.loggedIn',
        defaultMessage: 'You are already logged in!',
        description: 'You are already Logged-in',
    },
    userLoggedIn: {
        id: 'app.userLoggedIn',
        defaultMessage: 'You are already logged in as User, please logout in the main site to continue!',
        description: 'You are already Logged-in as user',
    }


});

export default messages;