import { updateHomePageCategoryMutation } from '../../lib/graphql';
import showToaster from '../../helpers/toastrMessgaes/showToaster';

export const updateHomePageCategory = ({ categoryList }) => {

    try {
        return async (dispatch, getState, { client }) => {
            const { data } = await client.mutate({
                mutation: updateHomePageCategoryMutation,
                variables: {
                    homePageCategoryList: categoryList.map(item => ({
                        title: item.title,
                        description: item.description,
                        logo: item.logo,
                        banner: item.banner
                    }))
                }
            });

            if (data?.updateHomePageCategory?.status == 200) showToaster({ messageId: 'updateHomeSuccess', toasterType: 'success' });
            else showToaster({ messageId: 'errorMessage', requestContent: data?.updateHomePageCategory?.errorMessage, toasterType: 'error' });
        };
    }
    catch (error) {
        showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
    }
}