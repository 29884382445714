import showToaster from '../../../helpers/toastrMessgaes/showToaster';

export function errorFileFormat(file, maxUploadSize) {

    let fileFormates = [
        'application/sql',
        'application/pdf',
        'application/vnd.oasis.opendocument.presentation',
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/epub+zip',
        'application/zip',
        'text/plain',
        'application/rtf',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.spreadsheet',
        'text/tab-separated-values',
        'text/calendar',
        'image/svg+xml'
    ];

    if (file && file.size > (1024 * 1024 * parseInt(maxUploadSize))) {
        showToaster({ messageId: 'limitError', toasterType: 'error' });
        return;
    }

    if (file && (file.accepted === false || fileFormates.indexOf(file.type) >= 0)) {
        showToaster({ messageId: 'filetypeError', toasterType: 'error' });
        return;
    }
}