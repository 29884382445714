import {
	SITE_SETTINGS_UPLOAD_START,
	SITE_SETTINGS_UPLOAD_ERROR,
	SITE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import { updateSiteSettingsMutation } from '../../lib/graphql';
import showToaster from '../../helpers/toastrMessgaes/showToaster';

const updateSiteSettings = ({
	siteName,
	siteTitle,
	metaDescription,
	facebookLink,
	twitterLink,
	instagramLink,
	logoHeight,
	logoWidth,
	metaKeyword,
	homeLogo,
	youtubeLink,
	favicon
}) => {
	return async (dispatch, getState, { client }) => {
		try {

			dispatch({ type: SITE_SETTINGS_UPLOAD_START });

			dispatch(setLoaderStart('SiteSettings'));

			const { data } = await client.mutate({
				mutation: updateSiteSettingsMutation,
				variables: {
					siteName,
					siteTitle,
					metaDescription,
					facebookLink,
					twitterLink,
					instagramLink,
					logoHeight,
					logoWidth,
					metaKeyword,
					homeLogo,
					youtubeLink,
					favicon
				}
			})

			dispatch(setLoaderComplete('SiteSettings'));

			if (data?.updateSiteSettings?.status == 200) {
				dispatch({ type: SITE_SETTINGS_UPLOAD_SUCCESS });
				showToaster({ messageId: 'siteSettingsSuccess', toasterType: 'success' });
			}
			else {
				let errorMessage = data && data.updateSiteSettings && data.updateSiteSettings.errorMessage;
				dispatch({ type: SITE_SETTINGS_UPLOAD_ERROR });
				showToaster({ messageId: 'errorMessage', requestContent: errorMessage, toasterType: 'error' });
			}
		}
		catch (err) {
			dispatch({ type: SITE_SETTINGS_UPLOAD_ERROR });
			showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
		}
	}
}

export default updateSiteSettings;
