import { removeStaticBannerImage } from './updatePrecautionNotification';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import { updatePrecautionNotificationImageMutation } from '../../../lib/graphql';

export const updatePrecautionNotificationImage = ({ id, imageName }, oldImage) => {

    return async (dispatch, getState, { client }) => {
        try {
            if (oldImage) await removeStaticBannerImage(oldImage);

            const { data } = await client.mutate({
                mutation: updatePrecautionNotificationImageMutation,
                variables: {
                    id,
                    imageName
                }
            });

            if (data.updatePrecautionNotificationImage && data.updatePrecautionNotificationImage.status == 200) {
                showToaster({ messageId: 'updateStatusSuccess', toasterType: 'success' });
            } else {
                showToaster({ messageId: 'errorMessage', requestContent: data?.updatePrecautionNotificationImage?.errorMessage, toasterType: 'error' });
            }
        }
        catch (error) {
            showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
        }
    };
}