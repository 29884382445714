import gql from 'graphql-tag';

export const addLocationMutation = gql`
mutation addLocation(
              $LocationName: String!,
      $coordinates: String!,
      $description: String!
    ) {
    addLocation(
      LocationName: $LocationName
      coordinates: $coordinates
      description: $description
    ){
      status
      errorMessage
    }
  }`;


export const deleteLocationmutation = gql`
  mutation deleteLocation($id: Int) {
      deleteLocation(id: $id) {
        status
        errorMessage
      }
    }`;


export const getLocationListQuery = gql`
query getLocationList($currentPage: Int, $searchList: String){
    getLocationList(currentPage: $currentPage, searchList:$searchList){
    count
    results{
        id
        locationName
        description
        isActive
    }
    }
}`;


export const updateLocationMutation = gql`
        mutation updateLocation(
  				    $LocationName: String!,
              $coordinates: String!,
              $id: Int!,
              $description: String!,
              $isActive: Boolean!
            ) {
              updateLocation(
              LocationName: $LocationName
              coordinates: $coordinates,
              id: $id,
              description: $description,
              isActive: $isActive
            ){
              status
              errorMessage
            }
          } `;
