import {
  ADD_PROMOCODE_START,
  ADD_PROMOCODE_SUCCESS,
  ADD_PROMOCODE_ERROR
} from '../../../constants';
import {
  addPromoCodeMutation,
  updatePromoCodeMutation
} from '../../../lib/graphql';
import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import showErrorMessage from '../../../helpers/showErrorMessage';

export const addPromoCode = (values) => {
  return async (dispatch, getState, { client }) => {
    try {

      let status, errorMessage = showErrorMessage({ errorCode: 'commonError' });
      dispatch({
        type: ADD_PROMOCODE_START,
        payload: {
          promoCodeLoading: true
        }
      });

      dispatch(setLoaderStart('AddPromoCode'));

      const mutation = values && values.id ? updatePromoCodeMutation : addPromoCodeMutation;
      if (!values.id) values.isEnable = true;

      const { data } = await client.mutate({
        mutation,
        variables: {
          id: values && values.id,
          title: values && values.title,
          description: values && values.description,
          code: values && values.code,
          type: values && values.type,
          promoValue: values && values.promoValue,
          currency: values && values.currency,
          expiryDate: values && values.expiryDate && values.expiryDate !== '' ? values.expiryDate : null,
          isEnable: values && values.isEnable.toString() === "true",
          imageName: values && values.imageName
        }
      });

      if (data && values.id) {
        status = data?.updatePromoCode?.status;
        errorMessage = data?.updatePromoCode?.errorMessage;
      }
      else if (data && !values.id) {
        status = data?.addPromoCode?.status;
        errorMessage = data?.addPromoCode?.errorMessage;
      }

      dispatch(setLoaderComplete('AddPromoCode'));

      if (status !== 200) {
        showToaster({ messageId: 'errorMessage', requestContent: errorMessage, toasterType: 'error' });
        dispatch({
          type: ADD_PROMOCODE_ERROR,
          payload: {
            promoCodeLoading: false,
            error: errorMessage
          }
        });
        return '';
      }

      history.push('/siteadmin/promo-code/list');
      showToaster({ messageId: 'addPromoCodeSuccess', requestContent: values?.id, toasterType: 'success' });
      await dispatch({
        type: ADD_PROMOCODE_SUCCESS,
        payload: {
          promoCodeLoading: false
        }
      });

      dispatch(setLoaderComplete('AddPromoCode'));
    }
    catch (error) {
      dispatch(setLoaderComplete('AddPromoCode'));
      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
      await dispatch({
        type: ADD_PROMOCODE_ERROR,
        payload: {
          promoCodeLoading: false,
          error
        }
      });
    }
  }
}