import gql from "graphql-tag";

export const addCategoryMutation = gql`
mutation addCategory(
  $id: Int,
  $name: String,
  $description: String,
  $logoImage: String,
  $bannerImage: String,
  $isPopular: Boolean,
  $isJobPhotoRequired: Boolean,
  $travellingPrice: Float,
  $userServiceFeeValue: Float,
  $partnerServiceFeeValue: Float,
  $pricingType: String,
  $status: String,
  $currency: String
) {
addCategory(
  id: $id,
  name: $name,
  description: $description,
  logoImage: $logoImage,
  bannerImage: $bannerImage,
  isPopular: $isPopular,
  isJobPhotoRequired: $isJobPhotoRequired,
  travellingPrice: $travellingPrice,
  userServiceFeeValue: $userServiceFeeValue,
  partnerServiceFeeValue: $partnerServiceFeeValue,
  pricingType: $pricingType,
  status: $status,
  currency: $currency
){
  status
  errorMessage
}
}`;

export const deleteCategoryMutation = gql`
mutation deleteCategory($id: Int) {
  deleteCategory(id: $id) {
    status
    errorMessage
  }
}`;

export const getAllCategoryqQuery = gql`
query getAllCategory($currentPage: Int, $searchList: String){
  getAllCategory(currentPage: $currentPage, searchList: $searchList){
   count
    results{
      id
      name
      description
      logoImage
      bannerImage
      isPopular
      isJobPhotoRequired
      travellingPrice
      userServiceFeeValue
      partnerServiceFeeValue
      pricingType
      status
      createdAt
      updatedAt
    }
  }
}`;

export const updateCategoryStatusMutation = gql`
mutation updateCategoryStatus($id: Int, $fieldName: String, $fieldValue: String) {
  updateCategoryStatus(id: $id, fieldName: $fieldName, fieldValue: $fieldValue) {
    status
    errorMessage
  }
}`;