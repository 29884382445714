import { change } from 'redux-form';
import {
	IDENTITY_IMAGE_UPLOAD_ERROR,
	IDENTITY_IMAGE_UPLOAD_START,
	IDENTITY_IMAGE_UPLOAD_SUCCESS
} from '../../../constants/index';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import { uploadIdentityImageMutation } from '../../../lib/graphql';


const uploadIdentity = (userId, imageName) => {

	return async (dispatch, getState, { client }) => {

		try {

			dispatch({
				type: IDENTITY_IMAGE_UPLOAD_START
			})

			const { data } = await client.mutate({
				mutation: uploadIdentityImageMutation,
				variables: {
					userId,
					imageName,
					type: 'identity'
				}
			});

			if (data?.uploadIdentityImage?.status == 200) {
				dispatch({
					type: IDENTITY_IMAGE_UPLOAD_SUCCESS
				})
				showToaster({ messageId: 'userExp', toasterType: 'success' });
				await dispatch(change("EditPartnerForm", 'identityDocument', data?.uploadIdentityImage?.identityDoc));
			} else {
				showToaster({ messageId: 'errorMessage', requestContent: data?.uploadIdentityImage?.errorMessage, toasterType: 'error' });
				dispatch({
					type: IDENTITY_IMAGE_UPLOAD_ERROR
				})
			}
		} catch (err) {
			showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
			dispatch({
				type: IDENTITY_IMAGE_UPLOAD_ERROR
			})
		}
	}
};

export default uploadIdentity;