import gql from 'graphql-tag';


export const updatePrecautionNotificationMutation = gql`
mutation updatePrecautionNotification(
    $id:Int, 
    $title:String!,
    $description:String!, 
    $isEnabled:Boolean!, 
    $imageName:String) {
        updatePrecautionNotification(
            id:$id, 
            title:$title, 
            description:$description, 
            isEnabled:$isEnabled, 
            imageName:$imageName) { 
                status
                errorMessage
        }
}`;

export const updatePrecautionNotificationImageMutation = gql`
    mutation updatePrecautionNotificationImage(
        $id:Int!,
        $imageName:String) {
            updatePrecautionNotificationImage(
                id:$id,
                imageName:$imageName) {
                    status
                    errorMessage
            }
    }`;