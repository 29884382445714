import history from '../../history';
import {
    deleteContentPageMutation,
    getContentPageDetailsQuery,
    updateContentPageStatusMutation
} from '../../lib/graphql';
import showToaster from '../../helpers/toastrMessgaes/showToaster';

const deleteContentPageDetails = (id) => {
    try {
        return async (dispatch, getState, { client }) => {

            try {
                const { data } = await client.mutate({
                    mutation: deleteContentPageMutation,
                    variables: { id },
                    refetchQueries: [{ query: getContentPageDetailsQuery }]
                });

                if (data?.deleteContentPage?.status === 200) {
                    showToaster({ messageId: 'deleteContentSuccess', toasterType: 'success' });
                    history.push('/siteadmin/contentpage/manage')
                }
                else if (data?.deleteContentPage?.status !== 200) {
                    showToaster({ messageId: 'errorMessage', requestContent: data?.deleteContentPage?.errorMessage, toasterType: 'error' });
                }
                else showToaster({ messageId: 'errorMessage', toasterType: 'error' });
            } catch (error) {
                showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
            }
        }
    }
    catch (error) {
        showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
    }
}

const updateContentPageStatus = (id, isEnable) => {
    try {
        return async (dispatch, getState, { client }) => {

            const { data } = await client.mutate({
                mutation: updateContentPageStatusMutation,
                variables: { id, isEnable },
                refetchQueries: [{ query: getContentPageDetailsQuery }]
            });

            if (data?.updateContentPageStatus?.status === 200) {
                showToaster({ messageId: 'updateStatusSuccess', toasterType: 'success' });
                history.push('/siteadmin/contentpage/manage')
            }
            else if (data?.updateContentPageStatus?.status !== 200) {
                showToaster({ messageId: 'errorMessage', requestContent: data?.updateContentPageStatus?.errorMessage, toasterType: 'error' });
            }
            else showToaster({ messageId: 'errorMessage', toasterType: 'error' });

        }
    } catch (error) {
        showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
    }
}

export { deleteContentPageDetails, updateContentPageStatus }