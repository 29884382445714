import {
    IMAGE_LIGHTBOX_OPEN,
    IMAGE_LIGHTBOX_CLOSE,
} from '../../constants';


const closeImageLightBox = () => {
    return async (dispatch) => {
        dispatch({
            type: IMAGE_LIGHTBOX_CLOSE,
            imageLightBox: false
        });

        return true;
    };
}

const openExactImageLightBox = (currentIndex) => {
    return async (dispatch) => {
        dispatch({
            type: IMAGE_LIGHTBOX_OPEN,
            imageLightBox: true,
            currentIndex: currentIndex
        });

        return true;
    };
}
export { closeImageLightBox, openExactImageLightBox }

