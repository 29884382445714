import {
    UPDATE_CONFIG_SETTINGS_START,
    UPDATE_CONFIG_SETTINGS_SUCCESS,
    UPDATE_CONFIG_SETTINGS_ERROR,
} from '../../../constants';
import { updateConfigSettingsMutation } from '../../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { siteSettings } from '../siteSettings';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

export const updateConfigSettings = (values) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: UPDATE_CONFIG_SETTINGS_START,
            });
            dispatch(setLoaderStart('configSettings'));

            const { data } = await client.mutate({
                mutation: updateConfigSettingsMutation,
                variables: values,
            })
            dispatch(setLoaderComplete('configSettings'));

            if (data?.updateConfigSettings?.status == 200) {
                dispatch({ type: UPDATE_CONFIG_SETTINGS_SUCCESS });
                showToaster({ messageId: 'updateConfigSettings', toasterType: 'success' })
                await dispatch(siteSettings());
            }
            else {
                let errorMessage = data?.updateConfigSettings?.errorMessage;
                dispatch({ type: UPDATE_CONFIG_SETTINGS_ERROR });
                showToaster({ messageId: 'errorMessage', requestContent: errorMessage, toasterType: 'error' })
            }

        }
        catch (err) {
            dispatch({ type: UPDATE_CONFIG_SETTINGS_ERROR });
            showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' })
        }
    }
}
