import gql from "graphql-tag";

export const updateConfigSettingsMutation = gql`
mutation updateConfigSettings(
    $smtpHost: String,
    $smtpPort: String,
    $smptEmail: String,
    $smtpSender: String,
    $smtpSenderEmail: String,
    $smtpPassWord: String,
    $maxUploadSize: String,
    $stripePublishableKey: String,
    $fcmPushNotificationKey:String
) {
    updateConfigSettings(
        smtpHost: $smtpHost,
        smtpPort: $smtpPort,
        smptEmail: $smptEmail,
        smtpSender: $smtpSender,
        smtpSenderEmail: $smtpSenderEmail,
        smtpPassWord: $smtpPassWord,
        maxUploadSize: $maxUploadSize,
        stripePublishableKey: $stripePublishableKey,
        fcmPushNotificationKey:$fcmPushNotificationKey
    ){
        status
        errorMessage
    }
}`;