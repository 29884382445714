import gql from "graphql-tag";

export const getContentPageDetailsQuery = gql`
query getContentPageDetails {
    getContentPageDetails {
        status
        errorMessage
        results{
        id
        metaTitle
        metaDescription
        pageUrl
        pageTitle
        content
        isEnable
        pageBanner
        }
    }
}`;

export const deleteContentPageMutation = gql`
mutation deleteContentPage ($id: Int!) {
    deleteContentPage (id: $id) {
        status
        errorMessage
    }
}`;


export const updateContentPageStatusMutation = gql`
mutation updateContentPageStatus ($id: Int, $isEnable: Boolean) {
    updateContentPageStatus(id: $id, isEnable: $isEnable){
        status
        errorMessage
    }
}
`;
