import gql from "graphql-tag";

export const addSubCategoryMutation = gql`
        mutation addSubCategory(
          $id: Int,
          $name: String,
          $description: String,
          $image: String,
          $categoryId: Int,
          $status: String,
        ) {
        addSubCategory(
          id: $id,
          name: $name,
          description: $description,
          image: $image,
          categoryId: $categoryId,
          status: $status
        ){
          status
          errorMessage
        }
      }`;


export const deleteSubCategoryMutation = gql`
      mutation deleteSubCategory($id: Int) {
          deleteSubCategory(id: $id) {
            status
            errorMessage
          }
        }`;

export const getAllSubCategoryQuery = gql`
query getAllSubCategory($currentPage: Int, $searchList: String){
    getAllSubCategory(currentPage: $currentPage, searchList: $searchList){
     count
      results{
        id
        name
        description
        image
        categoryId
        status
        createdAt
        updatedAt
      }
    }
  }`;

export const updateSubCategoryStatusMutation = gql`
  mutation updateSubCategoryStatus($id: Int, $fieldName: String, $fieldValue: String) {
      updateSubCategoryStatus(id: $id, fieldName: $fieldName, fieldValue: $fieldValue) {
        status
        errorMessage
      }
    }`;

