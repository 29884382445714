import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { updateUserMutation, getAllUsersQuery } from '../../../lib/graphql';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

export function editUser(values) {
  return async (dispatch, getState, { client }) => {

    dispatch(setLoaderStart('EditUser'));

    const { data } = await client.mutate({
      mutation: updateUserMutation,
      variables: {
        id: values.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneDialCode: values.phoneDialCode,
        phoneNumber: values.phoneNumber,
        userStatus: values.userStatus,
        isBan: values.isBan,
        phoneCountryCode: values.phoneCountryCode
      },
      refetchQueries: [{
        query: getAllUsersQuery,
        variables: {
          currentPage: 1,
          userType: 1
        }
      }]
    });

    dispatch(setLoaderComplete('EditUser'));

    if (data && data.updateUser && data.updateUser.status === 200) {
      history.push('/siteadmin/users')
      showToaster({ messageId: 'updateUser', toasterType: 'success' });
    } else {
      showToaster({ messageId: 'errorMessage', requestContent: data?.updateUser?.errorMessage, toasterType: 'error' });
    }
  }
}