import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import {
  CHANGE_CURRENCY_STATUS_START,
  CHANGE_CURRENCY_STATUS_SUCCESS,
  CHANGE_CURRENCY_STATUS_ERROR,
  SET_BASE_CURRENCY_START,
  SET_BASE_CURRENCY_SUCCESS,
  SET_BASE_CURRENCY_ERROR,
  CURRENCY_RATES_FETCH_SUCCESS,
  ADD_CURRENCY_START,
  ADD_CURRENCY_SUCCESS,
  ADD_CURRENCY_ERROR,
} from '../../../constants';

import {
  getCurrencyRatesQuery,
  getBaseCurrencySubQuery,
  getCurrencyQuery,
  updateCurrencyMutation,
  setBaseCurrencyMutation,
  allowPaymentCurrencyMutation,
  removeCurrencyMutation
} from '../../../lib/graphql';

import { closeCurrencyModal } from '../../../actions/siteadmin/modalActions';

export const updateCurrencyStatus = (id, isEnable, symbol) => {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: CHANGE_CURRENCY_STATUS_START,
      });

      let baseCurrencyId;

      const { data } = await client.query({ query: getBaseCurrencySubQuery, fetchPolicy: 'network-only' });
      if (data && data.getBaseCurrency) {
        baseCurrencyId = data.getBaseCurrency.id;
      }

      // Warn admind if he/she try to disable the base currency
      if (baseCurrencyId === id) {
        showToaster({ messageId: 'failedCurrency', toasterType: 'error' });
      } else {

        const { data } = await client.mutate({
          mutation: updateCurrencyMutation,
          variables: { id, isEnable, symbol },
          refetchQueries: [{ query: getCurrencyQuery, variables: { currentPage: 1, searchList: '' } }]
        });

        if (data.updateCurrency.status === 200) {
          dispatch({
            type: CHANGE_CURRENCY_STATUS_SUCCESS,
          });
          showToaster({ messageId: 'successCurrency', toasterType: 'success' });
        } else {
          dispatch({
            type: CHANGE_CURRENCY_STATUS_ERROR
          });
          showToaster({ messageId: 'errorMessage', requestContent: data?.updateCurrency?.errorMessage, toasterType: 'error' });
          return false;
        }
      }

    } catch (error) {

      dispatch({
        type: CHANGE_CURRENCY_STATUS_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
      return false;
    }
    return true;
  };
}

export const setBaseCurrency = (id) => {

  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: SET_BASE_CURRENCY_START,
      });

      const { data } = await client.mutate({
        mutation: setBaseCurrencyMutation,
        variables: { id },
        refetchQueries: [{ query: getCurrencyQuery, variables: { currentPage: 1, searchList: '' } }]
      });

      if (data?.setBaseCurrency?.status == 200) {
        dispatch({
          type: SET_BASE_CURRENCY_SUCCESS,
        });

        const currency = await client.query({ query: getCurrencyRatesQuery, fetchPolicy: 'network-only' })

        if (currency?.data?.getCurrencyRates) {

          let currencyRates;
          let base = currency?.data?.getCurrencyRates?.base;

          if (currency?.data?.getCurrencyRates?.rates) {
            currencyRates = JSON.parse(currency?.data?.getCurrencyRates?.rates);
          }

          showToaster({ messageId: 'baseCurrency', toasterType: 'success' });

          dispatch({
            type: CURRENCY_RATES_FETCH_SUCCESS,
            payload: {
              base,
              to: "",
              rates: currencyRates
            }
          })

        }
      }

    } catch (error) {
      dispatch({
        type: SET_BASE_CURRENCY_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
      return false;
    }
    return true;
  };
}

export const allowPaymentCurrency = (id, isPayment) => {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: SET_BASE_CURRENCY_START,
      });

      const { data } = await client.mutate({
        mutation: allowPaymentCurrencyMutation,
        variables: { id, isPayment },
        refetchQueries: [{ query: getCurrencyQuery }]
      });

      if (data?.allowPaymentCurrency?.status === 200) {
        dispatch({
          type: SET_BASE_CURRENCY_SUCCESS,
        });
        showToaster({ messageId: 'allowedCurrency', toasterType: 'success' });
      }

    } catch (error) {
      dispatch({
        type: SET_BASE_CURRENCY_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
      return false;
    }
    return true;
  };
}

export const addCurrency = (values) => {

  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: ADD_CURRENCY_START,
      });

      const { data } = await client.mutate({
        mutation: updateCurrencyMutation,
        variables: {
          isEnable: values.isEnable == '1' ? true : false,
          symbol: values.symbol
        },
        refetchQueries: [{ query: getCurrencyQuery, variables: { currentPage: 1, searchList: '' } }]
      });

      if (data?.updateCurrency?.status === 200) {
        dispatch({
          type: CHANGE_CURRENCY_STATUS_SUCCESS,
        });
        showToaster({ messageId: 'addCurrency', toasterType: 'success' });
      } else {
        dispatch({
          type: CHANGE_CURRENCY_STATUS_ERROR
        });
        showToaster({ messageId: 'errorMessage', requestContent: data?.updateCurrency?.errorMessage, toasterType: 'error' });

        return false;
      }
      dispatch(closeCurrencyModal());

    } catch (error) {
      dispatch({
        type: ADD_CURRENCY_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
      dispatch(closeCurrencyModal());
      return false;
    }
    return true;
  };
}


export const removeCurrency = (id, symbol) => {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: ADD_CURRENCY_START,
      });

      const { data } = await client.mutate({
        mutation: removeCurrencyMutation,
        variables: {
          id,
          symbol
        },
        refetchQueries: [{ query: getCurrencyQuery, variables: { currentPage: 1, searchList: '' } }]
      });

      if (data?.removeCurrency?.status === 200) {
        dispatch({
          type: ADD_CURRENCY_SUCCESS,
        });
        showToaster({ messageId: 'deleteCurrency', toasterType: 'success' });
      } else {
        dispatch({
          type: ADD_CURRENCY_ERROR
        });
        showToaster({ messageId: 'errorMessage', requestContent: data?.removeCurrency?.errorMessage, toasterType: 'error' });
        return false;
      }

    } catch (error) {
      dispatch({
        type: ADD_CURRENCY_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
      return false;
    }
    return true;
  };
}