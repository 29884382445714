import {
    HOMEPAGE_SETTINGS_UPLOAD_START,
    HOMEPAGE_SETTINGS_UPLOAD_ERROR,
    HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index'
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import { updateHomePageHomeMutation } from '../../lib/graphql';
import showToaster from '../../helpers/toastrMessgaes/showToaster';

const updateHomepageSettingsHome = (values) => {

    return async (dispatch, getState, { client }) => {

        try {

            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_START
            })

            dispatch(setLoaderStart('HomeSettingsForm'))
            const { data } = await client.mutate({
                mutation: updateHomePageHomeMutation,
                variables: {
                    homeSectionImage1: values?.homeSectionImage1,
                    homeSectionImage2: values?.homeSectionImage2,
                    homeSectionImage3: values?.homeSectionImage3,
                    homeSectionImage4: values?.homeSectionImage4,
                    homeSectionImage5: values?.homeSectionImage5,
                    homeSectionImage6: values?.homeSectionImage6,
                    homeSectionImage7: values?.homeSectionImage7,
                    homeSectionImage8: values?.homeSectionImage8,
                    homeSectionButton1: values?.homeSectionButton1,
                    homeSectionTitle1: values?.homeSectionTitle1,
                }
            })

            dispatch(setLoaderComplete('HomeSettingsForm'))
            if (data && data.updateHomePageHome && data.updateHomePageHome.status == 200) {

                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
                });
                showToaster({ messageId: 'updateFooterSuccess', toasterType: 'success' });
            } else {
                dispatch({
                    type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
                })
                showToaster({ messageId: 'errorMessage', requestContent: data?.updateHomePageHome?.errorMessage, toasterType: 'error' });
            }
        } catch (err) {
            dispatch({
                type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
            })
            showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
        }
    }
}

export default updateHomepageSettingsHome;