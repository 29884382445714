import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Lightbox from 'react-images';

import { isRTL } from '../../../helpers/formatLocale';
import { openExactImageLightBox } from '../../../actions/siteadmin/ImageLightBox';

class ImageSlider extends Component {

  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      sources: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { imageLightBox, currentIndex, sources } = nextProps;
    this.setState({ isOpen: imageLightBox });
    let sourceObject = {};
    if (currentIndex >= 0) {
      this.setState({ photoIndex: currentIndex });
    }
    if (sources != undefined) {
      let images = sources.map((item) => {
        return { src: item }
      })
      this.setState({ sources: images });
    }
  }

  componentDidMount() {
    const { imageLightBox, currentIndex, sources } = this.props;
    this.setState({ isOpen: imageLightBox });
    let sourceObject = {};
    if (currentIndex >= 0) {
      this.setState({ photoIndex: currentIndex });
    }
    if (sources != undefined) {
      let images = sources.map((item) => {
        return { src: item }
      })
      this.setState({ sources: images });
    }
  }

  closeLightbox = () => {
    const { closeImageLightBox, openExactImageLightBox } = this.props;
    this.setState({
      photoIndex: 0,
      isOpen: false,
    });
    openExactImageLightBox(0)
    closeImageLightBox();
  }

  gotoPrevious = () => {
    this.setState({
      photoIndex: this.state.photoIndex - 1,
    });
  }

  gotoNext = () => {
    this.setState({
      photoIndex: this.state.photoIndex + 1,
    });
  }

  gotoImage = (index) => {
    this.setState({
      photoIndex: index,
    });
  }

  render() {
    const { photoIndex, isOpen, sources } = this.state;
    const { locale } = this.props.intl;

    return (
      <div >
        {isOpen && (
          <Lightbox
            images={sources}
            isOpen={isOpen}
            currentImage={photoIndex}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            onClose={this.closeLightbox}
            onClickThumbnail={this.gotoImage}
            showCloseButton={true}
            enableKeyboardInput={true}
            showImageCount={!isRTL(locale)}
          />
        )}
      </div>
    );
  }
}

const mapState = (state) => ({
  currentIndex: state.adminModalStatus.currentIndex
});

const mapDispatch = {
  openExactImageLightBox
};

export default injectIntl(connect(mapState, mapDispatch)(ImageSlider));
