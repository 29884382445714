import {
    CATEGORY_UPDATE_SUCCESS,
    CATEGORY_UPDATE_START,
    CATEGORY_UPDATE_ERROR
} from '../../../constants/index';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import { getAllCategoryqQuery, updateCategoryStatusMutation } from '../../../lib/graphql';

const updateCategoryStatus = (id, currentPage, fieldName, fieldValue) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: CATEGORY_UPDATE_START
            });

            const { data } = await client.mutate({
                mutation: updateCategoryStatusMutation,
                variables: {
                    id,
                    fieldName,
                    fieldValue
                },
                refetchQueries: [{ query: getAllCategoryqQuery, variables: { currentPage, searchList: '' } }]
            });

            if (data?.updateCategoryStatus?.status == 200) {
                dispatch({
                    type: CATEGORY_UPDATE_SUCCESS
                });
                showToaster({ messageId: 'addCatSuccess', requestContent: id, toasterType: 'success' });
            } else {
                dispatch({
                    type: CATEGORY_UPDATE_ERROR
                });
                showToaster({ messageId: 'errorMessage', requestContent: data?.updateCategoryStatus?.errorMessage, toasterType: 'error' });
            }
        } catch (err) {
            showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
            dispatch({
                type: CATEGORY_UPDATE_ERROR
            });
        }

    }
};

export default updateCategoryStatus;