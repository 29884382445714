import {
    USER_DELETE_SUCCESS,
    USER_DELETE_START,
    USER_DELETE_ERROR
} from '../../../constants/index';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import { deleteUserMutation, getAllUsersQuery } from '../../../lib/graphql';

const deleteUser = (profileId, currentPage, userType) => {
    return async (dispatch, getState, { client }) => {

        try {

            dispatch({
                type: USER_DELETE_START,
                payload: {
                    deleteLoading: true
                }
            });

            const { data } = await client.mutate({
                mutation: deleteUserMutation,
                variables: {
                    profileId
                },
                refetchQueries: [{ query: getAllUsersQuery, variables: { currentPage, userType } }]
            });

            if (data?.deleteUser?.status === 200) {
                dispatch({
                    type: USER_DELETE_SUCCESS,
                    payload: {
                        deleteLoading: false
                    }
                });
                showToaster({ messageId: 'delUser', requestContent: userType, toasterType: 'success' });
            } else {
                dispatch({
                    type: USER_DELETE_ERROR,
                    payload: {
                        deleteLoading: false
                    }
                });

                showToaster({ messageId: 'errorMessage', requestContent: data?.deleteUser?.errorMessage, toasterType: 'error' });
            }
        } catch (err) {
            showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
            dispatch({
                type: USER_DELETE_ERROR,
                payload: {
                    deleteLoading: false
                }
            });
        }

    }
};

export default deleteUser;