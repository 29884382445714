import {
    ADMIN_UPDATE_STATIC_START,
    ADMIN_UPDATE_STATIC_SUCCESS,
    ADMIN_UPDATE_STATIC_ERROR
} from '../../constants';
import history from '../../history';
import { updateStaticPageMutation, getEditStaticPageQuery } from '../../lib/graphql';
import showToaster from '../../helpers/toastrMessgaes/showToaster';

export const updateStaticPage = (values) => {

    return async (dispatch, getState, { client }) => {
        try {
            dispatch({ type: ADMIN_UPDATE_STATIC_START });
            const { data } = await client.mutate({
                mutation: updateStaticPageMutation,
                variables: {
                    content: values.content,
                    metaTitle: values.metaTitle,
                    metaDescription: values.metaDescription,
                    pageBanner: values.pageBanner,
                    id: values.id
                },
                refetchQueries: [{ query: getEditStaticPageQuery, variables: { id: values.id } }]
            });


            if (data?.updateStaticPage?.status == 200) {
                showToaster({ messageId: 'updateStatusSuccess', toasterType: 'success' });
                history.push('/siteadmin/staticpage/manage');
                dispatch({ type: ADMIN_UPDATE_STATIC_SUCCESS });
            }
            else {
                showToaster({ messageId: 'errorMessage', requestContent: data?.updateStaticPage?.errorMessage, toasterType: 'error' });
                dispatch({ type: ADMIN_UPDATE_STATIC_ERROR });
            }

        } catch (error) {
            showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
            dispatch({ type: ADMIN_UPDATE_STATIC_ERROR });
            return false;
        }
        return true;
    };
}