import { updateMobileSettings } from '../../../actions/siteadmin/updateMobileSettings'
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

async function submit(values, dispatch) {

    if (values.isRequestTimerToneEnable == '1' && !values.requestTimeTone) {
        showToaster({ messageId: 'timer', toasterType: 'error' });
        return;
    }
    
    values['randomProviderSelection'] = values.randomProviderSelection == true ? '1' : '0'
    values['preferredProviderSelection'] = values.preferredProviderSelection == true ? '1' : '0'

    await dispatch(updateMobileSettings(values));
}

export default submit;