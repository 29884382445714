import gql from 'graphql-tag';

export const addPromoCodeMutation = gql`
mutation( 
  $title: String!, 
  $description: String!, 
  $code: String!, 
  $type: Int,
  $promoValue: Float!, 
  $currency: String,
  $expiryDate: String,
  $imageName: String
) {
  addPromoCode(
      title: $title,
      description: $description,
      code: $code,
      type: $type,
      promoValue: $promoValue,
      currency: $currency,
      expiryDate: $expiryDate,
      imageName: $imageName
  ) {
      status
      errorMessage
  }
}`;


export const updatePromoCodeMutation = gql`
mutation( 
  $id: Int!,
  $title: String!, 
  $description: String!, 
  $code: String!, 
  $type: Int,
  $promoValue: Float!, 
  $currency: String,
  $expiryDate: String,
  $isEnable: Boolean,
  $imageName: String
) {
  updatePromoCode(
      id: $id,
      title: $title,
      description: $description,
      code: $code,
      type: $type,
      promoValue: $promoValue,
      currency: $currency,
      expiryDate: $expiryDate,
      isEnable: $isEnable,
      imageName: $imageName
  ) {
      status
      errorMessage
  }
}`;


export const deletePromoCodeMutation = gql`
mutation($id: Int!) {
  deletePromoCode(id: $id) {
      status
      errorMessage
  }
}`;


export const getAllPromoCodeQuery = gql`
query($currentPage: Int) {
    getAllPromoCode(currentPage: $currentPage) {
        status
        errorMessage
        count
        results {
          id
          title
          description
          imageName
          code
          type
          promoValue
          currency
          expiryDate
          isEnable
          createdAt
          updatedAt
        }
    }
}`;
