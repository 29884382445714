import gql from 'graphql-tag';

export const getAllPaymentMethodsQuery = gql`
query {
	getAllPaymentMethods {
		results {
			id
			paymentName
			isEnable
			paymentType
		}
		status
		errorMessage
	}
}`;

export const updatePaymentMutation = gql`
mutation updatePayment ($id: Int, $status: Boolean){
    updatePayment(id: $id, status: $status){
        status
        errorMessage
      }
  }`;