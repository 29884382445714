import {
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DELETE_START,
    CATEGORY_DELETE_ERROR
} from '../../../constants/index';
import { setLoaderComplete, setLoaderStart } from '../../loader/loader';
import { deleteSubCategoryMutation, getAllSubCategoryQuery } from '../../../lib/graphql';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

const deleteSubCategory = (id, currentPage) => {

    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: CATEGORY_DELETE_START
            });

            dispatch(setLoaderStart('DeleteSubCategory'));

            const { data } = await client.mutate({
                mutation: deleteSubCategoryMutation,
                variables: {
                    id
                },
                refetchQueries: [{ query: getAllSubCategoryQuery, variables: { currentPage, searchList: '' } }]
            });

            dispatch(setLoaderComplete('DeleteSubCategory'));

            if (data?.deleteSubCategory?.status == 200) {

                dispatch({
                    type: CATEGORY_DELETE_SUCCESS
                });
                showToaster({ messageId: 'removeSubCatSuccess', toasterType: 'success' });

            } else {

                dispatch({
                    type: CATEGORY_DELETE_ERROR
                });
                showToaster({ messageId: 'errorMessage', requestContent: data?.deleteSubCategory?.errorMessage, toasterType: 'error' });
            }
        } catch (err) {
            showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
            dispatch({
                type: CATEGORY_DELETE_ERROR
            });
        }

    }
};

export default deleteSubCategory;