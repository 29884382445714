import histroy from '../../../history';
import { updateLocationMutation } from '../../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

export const updateLocation = (LocationName, coordinates, id, description, isActive) => {

  return async (dispatch, getState, { client }) => {

    try {

      let coordinatesError = '';
      if (!coordinates || coordinates == '' || coordinates == 'null') {
        showToaster({ messageId: 'selectLoc', toasterType: 'error' });
        coordinatesError = 'yes';
        return;
      }
      isActive = Number(isActive);

      dispatch(setLoaderStart('updateLocation'));

      if (coordinatesError != '') {
        dispatch(setLoaderComplete('updateLocation'));
      }

      const { data } = await client.mutate({
        mutation: updateLocationMutation,
        variables: {
          LocationName,
          coordinates,
          id,
          description,
          isActive
        },

      });

      dispatch(setLoaderComplete('updateLocation'));

      if (data?.updateLocation?.status === 200) {
        showToaster({ messageId: 'updateLoc', toasterType: 'success' });
        histroy.push('/siteadmin/location');
      } else if (data?.updateLocation?.status !== 200) {
        showToaster({ messageId: 'errorMessage', requestContent: data?.updateLocation?.errorMessage, toasterType: 'error' });
      }
    } catch (error) {
      showToaster({ messageId: 'errorMessage', requestContent: error, toasterType: 'error' });
    }

  }
};