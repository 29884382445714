import React from 'react';

import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ConfigSettings.css';
import cx from 'classnames';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';

import getSiteSettings from './getSiteSettings.graphql';
//conponents
import ConfigSettingsForm from '../../../components/SiteAdmin/ConfigSettingsForm '
import Loader from '../../../components/Common/Loader/Loader';
import { injectIntl } from 'react-intl';

class ConfigSettings extends React.Component{
    
  render() {
    const { getSiteSettings: { loading, getSiteSettings, refetch }, intl: { formatMessage } } = this.props;
    let siteSettingsCollection = {}


    getSiteSettings && getSiteSettings.results && getSiteSettings.results.length > 0 && getSiteSettings.results.map((item) => siteSettingsCollection[item.name] = item.value);

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
          <div className={cx(s.paddingRoutesSection, s.responsiveNoPadding)}>
              {!loading && <ConfigSettingsForm refetch={refetch} getSiteSettings={getSiteSettings} initialValues={siteSettingsCollection} />}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}


  export default compose(
    injectIntl,
    withStyles(s),
    graphql(getSiteSettings, {
      name: 'getSiteSettings',
      options: (props) => ({
        fetchPolicy: "network-only",
        variables: { type: 'config_settings' },
        ssr: true,
      })
    })
  )(ConfigSettings)