import {
	CREATE_ADMIN_USER_START,
	CREATE_ADMIN_USER_SUCCESS,
	CREATE_ADMIN_USER_ERROR,
} from '../../../constants';
import { setRuntimeVariable } from '../../runtime';
import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { getAdminUser } from '../../../actions/siteadmin/AdminUser/manageAdminUser';
import { getPrivileges } from '../../../actions/siteadmin/AdminRoles/manageAdminRoles';
import { adminLoginQuery as query } from '../../../lib/graphql';
import { siteSettings } from '../siteSettings';


export const login = (email, password) => {
	return async (dispatch, getState, { client }) => {

		try {
			await dispatch({
				type: CREATE_ADMIN_USER_START,

			});

			dispatch(setLoaderStart('AdminLogin'));

			const { data } = await client.query({
				query,
				variables: {
					email,
					password,
				},
				fetchPolicy: 'network-only'
			});

			dispatch(setLoaderComplete('AdminLogin'));

			if (data?.adminUserLogin?.status == 200) {
				await dispatch({
					type: CREATE_ADMIN_USER_SUCCESS,
				});
				dispatch(setRuntimeVariable({
					name: 'isAdminAuthenticated',
					value: true,
				}));

				dispatch(setRuntimeVariable({
					name: 'isSuperAdmin',
					value: data?.adminUserLogin?.isSuperAdmin
				}));
				dispatch(getAdminUser());
				dispatch(getPrivileges());
				dispatch(siteSettings());

				history.push('/siteadmin');
				return false;


			} else {

				if (data?.adminUserLogin?.isSuperAdmin) {
					dispatch(setRuntimeVariable({
						name: 'isAdminAuthenticated',
						value: true,
					}));
					dispatch(setRuntimeVariable({
						name: 'isSuperAdmin',
						value: data?.adminUserLogin?.isSuperAdmin
					}));
					dispatch(getAdminUser());
					dispatch(getPrivileges());
					dispatch(siteSettings());

				}
				await dispatch({
					type: CREATE_ADMIN_USER_ERROR,
				});

				return data?.adminUserLogin?.errorMessage;
			}


		} catch (error) {
			await dispatch({
				type: CREATE_ADMIN_USER_ERROR,

			});
			dispatch(setLoaderComplete('AdminLogin'));
			return false;
		}
	}
}