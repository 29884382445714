import history from '../../history';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import { updatePartnerMutation } from '../../lib/graphql';
import showToaster from '../../helpers/toastrMessgaes/showToaster';

export const editPartner = (values) => {
  try {
    return async (dispatch, getState, { client }) => {
      let errorMessage = 'Oops! something went wrong. Try again!';

      dispatch(setLoaderStart('EditProvider'));

      const { data } = await client.mutate({
        mutation: updatePartnerMutation,
        variables: {
          id: values.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneDialCode: values.phoneDialCode,
          phoneNumber: values.phoneNumber,
          userStatus: values.userStatus,
          isBan: values.isBan,
          phoneCountryCode: values.phoneCountryCode
        }
      });

      dispatch(setLoaderComplete('EditProvider'));

      if (data?.updatePartner?.status === 200) {
        history.push('/siteadmin/partners');
        showToaster({ messageId: 'editPartnerSuccess', toasterType: 'success' });
      } else {
        errorMessage = (data?.updatePartner?.errorMessage) ? data.updatePartner.errorMessage : errorMessage;
        showToaster({ messageId: 'errorMessage', requestContent: errorMessage, toasterType: 'error' });
      }
    }
  }
  catch (error) {
    showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
  }
}