import {
  UPDATE_SMS_START,
  UPDATE_SMS_SUCCESS,
  UPDATE_SMS_ERROR
} from '../../../constants';
import history from '../../../history';
import { updateSmsMethodMutation } from '../../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

export const updateSmsMethod = (values) => {

  return async (dispatch, getState, { client }) => {
    try {
      let status, errorMessage;
      dispatch({
        type: UPDATE_SMS_START,
        payload: {
          loading: true
        }
      });

      dispatch(setLoaderStart('UpdateSmsMethod'));

      const { data } = await client.mutate({
        mutation: updateSmsMethodMutation,
        variables: {
          id: values?.id,
          status: values?.status,
          accountId: values?.accountId,
          securityId: values?.securityId,
          phoneNumber: values?.phoneNumber,
          phoneDialCode: values?.phoneDialCode,
          phoneCountryCode: values?.phoneCountryCode,
          updateStatus: values?.updateStatus
        }
      });

      if (data) {
        status = data?.updateSmsMethod?.status;
        errorMessage = data?.updateSmsMethod?.errorMessage;
      }

      dispatch(setLoaderComplete('UpdateSmsMethod'));

      if (status !== 200) {
        showToaster({ messageId: 'errorMessage', requestContent: errorMessage, toasterType: 'error' });
        dispatch({
          type: UPDATE_SMS_ERROR,
          payload: {
            loading: false,
            error: errorMessage
          }
        });
        return '';
      }

      history.push('/siteadmin/sms-methods');
      showToaster({ messageId: 'updateSms', toasterType: 'success' });
      await dispatch({
        type: UPDATE_SMS_SUCCESS,
        payload: {
          loading: false
        }
      });

      dispatch(setLoaderComplete('UpdateSmsMethod'));
    }
    catch (error) {
      dispatch(setLoaderComplete('UpdateSmsMethod'));
      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
      await dispatch({
        type: UPDATE_SMS_ERROR,
        payload: {
          loading: false,
          error
        }
      });
    }
  }
}