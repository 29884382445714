
export async function workTimeConversion(duration) {
    duration = duration.toFixed(2);
    if (duration >= 60) {
      var totalHour = Math.floor(duration / 60);
      var totalMin = Math.floor(duration % 60);
      return totalHour + "h " + totalMin + "m";
    } else if (duration >= 1 && duration < 60) {
      var totalMin = Math.floor(duration);
      var totalSec = duration - totalMin;
      totalSec = Math.floor(totalSec * 60);
      return totalMin + "m " + totalSec + "s";
    } else {
      var totalSec = Math.floor(duration * 60);
      return totalSec + "s";
    }
}


export function getHours(totalDuration) {
    if (totalDuration) {
        return (totalDuration / 60);
    } else {
        return 0;
    }
}