import messages from '../../../locale/messages';

const validate = values => {

  const errors = {}

  if (!values.fcmPushNotificationKey || values.fcmPushNotificationKey.toString().trim() == "") {
    errors.fcmPushNotificationKey = messages.required;
  }

  if (!values.smtpHost || values.smtpHost.toString().trim() == "") {
    errors.smtpHost = messages.required;
  }

  if (!values.smtpPort || values.smtpPort.toString().trim() == "") {
    errors.smtpPort = messages.required;
  }

  if (!values.smptEmail || values.smptEmail.toString().trim() == "") {
    errors.smptEmail = messages.required && messages.required;
  }

  if (!values.smtpSender || values.smtpSender.toString().trim() == "") {
    errors.smtpSender = messages.required;
  }

  if (!values.smtpPassWord || values.smtpPassWord.toString().trim() == "") {
    errors.smtpPassWord = messages.required;
  }


  if (!values.smtpSenderEmail) {
    errors.smtpSenderEmail = messages.required && messages.required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.smtpSenderEmail)) {
    errors.smtpSenderEmail = messages.emailInvalid && messages.emailInvalid;
  }

  if (!values.stripePublishableKey || values.stripePublishableKey.toString().trim() == "") {
    errors.stripePublishableKey = messages.required;
  }

  if (!values.maxUploadSize || values.maxUploadSize.toString().trim() == "") {
    errors.maxUploadSize = messages.required;
  }else if(values.maxUploadSize && (values.maxUploadSize <= 0 || isNaN(values.maxUploadSize))){
    errors.maxUploadSize = messages.invalid;
  }

  return errors;
}

export default validate;
