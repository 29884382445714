import {
  SET_CASH_PAYOUT_START,
  SET_CASH_PAYOUT_SUCCESS,
  SET_CASH_PAYOUT_ERROR,
} from '../../../constants';
import {
  updateCashPayoutMutation,
  getPayoutListQuery
} from '../../../lib/graphql';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

export const updateCashPayout = (id, payoutStatus, currentPage) => {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: SET_CASH_PAYOUT_START,
      });

      const { data } = await client.mutate({
        mutation: updateCashPayoutMutation,
        variables: { id, payoutStatus },
        refetchQueries: [{ query: getPayoutListQuery, variables: { currentPage, searchList: '' } }]
      });

      if (data?.updateCashPayout?.status == 200) {

        dispatch({
          type: SET_CASH_PAYOUT_SUCCESS,
        });
        showToaster({ messageId: 'updateCashPayout', toasterType: 'success' });

      } else {

        dispatch({
          type: SET_CASH_PAYOUT_ERROR
        });

        showToaster({ messageId: 'errorMessage', requestContent: data?.updateCashPayout?.errorMessage, toasterType: 'error' });

        return false;
      }
    } catch (error) {

      dispatch({
        type: SET_CASH_PAYOUT_ERROR
      });

      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });

      return false;
    }
    return true;
  };
}
