import gql from 'graphql-tag';

export const updateCashPayoutMutation = gql`
mutation updateCashPayout ($id: Int, $payoutStatus: Boolean){
  updateCashPayout(id: $id, payoutStatus: $payoutStatus){
      status
      errorMessage
    }
}`;

export const getPayoutListQuery = gql`
query getPayoutList($currentPage: Int, $searchList: String){
    getPayoutList(currentPage: $currentPage, searchList:$searchList){
      count
      results{
      id
      status
      userDetails{
        firstName
        userData{
          email
        }
      }
      totalFare
      isPayoutPaid
      isBanStatus
      paymentType
    }
  }
}`;


export const updatePayoutMutation = gql`
mutation updatePayout ($id: Int!, $isBanStatus: Boolean!){
  updatePayout(id: $id, isBanStatus: $isBanStatus){
      status
      errorMessage
    }
}`;