import {
	HOMEPAGE_SETTINGS_UPLOAD_START,
	HOMEPAGE_SETTINGS_UPLOAD_ERROR,
	HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index';
import { updateHomePageCityMutation } from '../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import showToaster from '../../helpers/toastrMessgaes/showToaster';

const updateHomepageSettingsCity = (values) => {

	return async (dispatch, getState, { client }) => {
		try {

			dispatch({
				type: HOMEPAGE_SETTINGS_UPLOAD_START
			})

			dispatch(setLoaderStart('CitySettingsForm'))
			const { data } = await client.mutate({
				mutation: updateHomePageCityMutation,
				variables: {
					citySectionTitle1: values && values.citySectionTitle1,
					citySectionContent1: values && values.citySectionContent1
				}
			})

			dispatch(setLoaderComplete('CitySettingsForm'))
			if (data?.updateHomePageCity?.status == 200) {

				dispatch({
					type: HOMEPAGE_SETTINGS_UPLOAD_SUCCESS
				})
				showToaster({ messageId: 'updateCitySuccess', toasterType: 'success' });
			} else {
				dispatch({
					type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
				})
				showToaster({ messageId: 'errorMessage', requestContent: data?.updateHomePageCity?.errorMessage, toasterType: 'error' });
			}
		} catch (err) {
			dispatch({
				type: HOMEPAGE_SETTINGS_UPLOAD_ERROR
			})
			showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
		}

	}
}

export default updateHomepageSettingsCity;