import {
    SITE_SETTINGS_ERROR,
    SITE_SETTINGS_START,
    SITE_SETTINGS_SUCCESS
} from '../../constants';
import { getSiteSettingsQuery } from '../../lib/graphql';

export const siteSettings = () => {
    return async (dispatch, getState, { client }) => {
        try {

            dispatch({
                type: SITE_SETTINGS_START
            })

            let type = 'site_settings', settingsData = {};

            const { data } = await client.query({
                query: getSiteSettingsQuery,
                variables: { type },
                fetchPolicy: 'network-only'
            });

            if (data?.getSiteSettings?.status === 200) {

                data?.getSiteSettings?.results?.map((item, key) => {
                    settingsData[item.name] = item.value;
                });

                dispatch({
                    type: SITE_SETTINGS_SUCCESS,
                    data: settingsData
                })

            } else {
                dispatch({
                    type: SITE_SETTINGS_ERROR
                });
            }
        } catch (error) {
            dispatch({
                type: SITE_SETTINGS_ERROR,
            });
        }
    }
}