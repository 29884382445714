import gql from "graphql-tag";

export const updateTempImagesMutation = gql`
        mutation updateTempImages(
          $tableName: String,
          $fieldName: String,
          $fileName: String
        ) {
        updateTempImages(
          tableName: $tableName,
          fieldName: $fieldName,
          fileName: $fileName
        ){
          status
        }
      }`;