import gql from 'graphql-tag';
import {
    DELETE_LOCATION_START,
    DELETE_LOCATION_ERROR,
    DELETE_LOCATION_SUCCESS
} from '../../../constants';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import { deleteLocationmutation, getLocationListQuery } from '../../../lib/graphql';

export const deleteLocation = (id, currentPage) => {
    return async (dispatch, getState, { client }) => {

        try {

            dispatch({
                type: DELETE_LOCATION_START
            });

            const { data } = await client.mutate({
                mutation: deleteLocationmutation,
                variables: {
                    id
                },
                refetchQueries: [{ query: getLocationListQuery, variables: { currentPage, searchList: '' } }]
            });

            if (data?.deleteLocation?.status == 200) {
                dispatch({
                    type: DELETE_LOCATION_SUCCESS
                })
                showToaster({ messageId: 'delLoc', toasterType: 'success' });
            } else {
                dispatch({
                    type: DELETE_LOCATION_ERROR
                });
                showToaster({ messageId: 'errorMessage', requestContent: data?.deleteLocation?.errorMessage, toasterType: 'error' });
            }

        } catch (err) {
            dispatch({
                type: DELETE_LOCATION_ERROR
            })
            showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
        }
    }
}