import histroy from '../../../history';
import { addLocationMutation } from '../../../lib/graphql/location';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

export const addLocation = (LocationName, coordinates, description) => {

  try {

    let coordinatesError = '';
    if (!coordinates || coordinates == '') {
      showToaster({ messageId: 'selectLoc', toasterType: 'error' });
      coordinatesError = 'yes';
    }

    return async (dispatch, getState, { client }) => {

      dispatch(setLoaderStart('AddLocation'));

      if (coordinatesError != '') {
        dispatch(setLoaderComplete('AddLocation'));
      }

      const { data } = await client.mutate({
        mutation: addLocationMutation,
        variables: {
          LocationName,
          coordinates,
          description
        },

      });

      dispatch(setLoaderComplete('AddLocation'));

      if (data?.addLocation?.status === 200) {
        showToaster({ messageId: 'addLoc', toasterType: 'success' });
        histroy.push('/siteadmin/location');
      } else if (data?.addLocation?.status !== 200) {
        showToaster({ messageId: 'errorMessage', requestContent: data?.addLocation?.errorMessage, toasterType: 'error' });
      }
    }
  } catch (error) {
    showToaster({ messageId: 'errorMessage', requestContent: error, toasterType: 'error' });
  }
};