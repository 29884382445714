import gql from "graphql-tag";

export const updateCancelReasonMutation = gql`
mutation(
  $id: Int, 
  $userType: Int, 
  $reason: String, 
  $isActive: Boolean
  ) {
  updateCancelReason(
      id: $id, 
      userType: $userType, 
      reason: $reason, 
      isActive: $isActive
      ) {
      status
      errorMessage
  }
}`;

export const removeCancelReasonMutation = gql`
mutation($id: Int) {
  removeCancelReason(id: $id) {
      status
      errorMessage
  }
}`;

export const getAllCancelReasonQuery = gql`
query getAllCancelReason ($searchList: String $currentPage: Int) {
  getAllCancelReason(searchList: $searchList currentPage: $currentPage) {
    count
    results{
      id
      reason
      userType
      isActive
    }
    status
  }
}`