import {
  DELETE_PROMOCODE_START,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_ERROR
} from '../../../constants';
import { deletePromoCodeMutation, getAllPromoCodeQuery } from '../../../lib/graphql';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

export const deletePromoCode = (id) => {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: DELETE_PROMOCODE_START,
        payload: {
          promoCodeLoading: true
        }
      });

      const { data } = await client.mutate({
        mutation: deletePromoCodeMutation,
        variables: { id },
        refetchQueries: [{
          query: getAllPromoCodeQuery,
          variables: { currentPage: 1 }
        }]
      });

      if (data?.deletePromoCode?.status === 200) {
        showToaster({ messageId: 'deletePromoCodeSuccess', toasterType: 'success' });
        await dispatch({
          type: DELETE_PROMOCODE_SUCCESS,
          payload: {
            promoCodeLoading: false
          }
        });
      }
      else {
        showToaster({ messageId: 'errorMessage', requestContent: data?.deletePromoCode?.errorMessage, toasterType: 'error' });
        await dispatch({
          type: DELETE_PROMOCODE_ERROR,
          payload: {
            promoCodeLoading: false,
            error: data?.deletePromoCode?.errorMessage
          }
        });
      }
    } catch (error) {
      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
      await dispatch({
        type: DELETE_PROMOCODE_ERROR,
        payload: {
          promoCodeLoading: false,
          error: "Something went wrong! " + error
        }
      });
    }
  }
}