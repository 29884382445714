import { initialize, reset } from 'redux-form';
import {
  OPEN_ADMIN_USER_MODAL,
  CLOSE_ADMIN_USER_MODAL,
  OPEN_ADMIN_ROLES_MODAL,
  CLOSE_ADMIN_ROLES_MODAL,
  OPEN_HEADER_MODAL,
  CLOSE_HEADER_MODAL,
  EDIT_BOOKING_MODAL,
  CANCEL_BOOKING_MODAL,
  OPEN_CURRENCY_MODAL,
} from '../../constants';

const openAdminRolesModal = (type, formData) => {
  return (dispatch, getState) => {
    if (type === 'edit') {
      dispatch(initialize("AdminRolesForm", formData, true));
    }

    dispatch({
      type: OPEN_ADMIN_ROLES_MODAL,
      payload: {
        adminRolesModal: true,
        adminRolesModalType: type
      }
    });
  }
}

const closeAdminRolesModal = () => {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_ADMIN_ROLES_MODAL,
      payload: {
        adminRolesModal: false
      }
    });
  }
}

const openAdminUserModal = (type, formData) => {
  return (dispatch, getState) => {
    if (type === 'edit') {
      dispatch(initialize("AdminUserForm", formData, true));
    }

    dispatch({
      type: OPEN_ADMIN_USER_MODAL,
      payload: {
        adminUserModal: true,
        adminUserModalType: type
      }
    });
  }
}

const closeAdminUserModal = () => {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_ADMIN_USER_MODAL,
      payload: {
        adminUserModal: false
      }
    });
  }
}

const openHeaderModal = (modalType) => {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_HEADER_MODAL,
      payload: {
        modalType,
        actionValue: true
      }
    });
  };
}

const closeHeaderModal = (modalType) => {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_HEADER_MODAL,
      payload: {
        modalType,
        actionValue: false
      }
    });
  };
}

const openEditBookingModal = (formData) => {
  return (dispatch, getState) => {
    dispatch(initialize("EditBookingForm", formData, true));
    dispatch({
      type: EDIT_BOOKING_MODAL,
      payload: { editBookingModal: true }
    });
  };
}

const closeEditBookingModal = () => {
  return (dispatch, getState) => {
    dispatch(reset("EditBookingForm"));
    dispatch({
      type: EDIT_BOOKING_MODAL,
      payload: { editBookingModal: false }
    });
  };
}

const openCancelBookingModal = (formData) => {
  return (dispatch, getState) => {
    dispatch(initialize("CancelBookingForm", formData, true));
    dispatch({
      type: CANCEL_BOOKING_MODAL,
      payload: { cancelBookingModal: true }
    });
  };
}

const closeCancelBookingModal = () => {
  return (dispatch, getState) => {
    dispatch(reset("CancelBookingForm"));
    dispatch({
      type: CANCEL_BOOKING_MODAL,
      payload: { cancelBookingModal: false }
    });
  };
}

const openCurrencyModal = () => {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_CURRENCY_MODAL,
      payload: {
        currencyModal: true,
      }
    });
  }
}

const closeCurrencyModal = () => {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_CURRENCY_MODAL,
      payload: {
        currencyModal: false
      }
    });
  }
}

export {
  openAdminRolesModal,
  closeAdminRolesModal,
  openAdminUserModal,
  closeAdminUserModal,
  openHeaderModal,
  closeHeaderModal,
  openEditBookingModal,
  closeEditBookingModal,
  openCancelBookingModal,
  closeCancelBookingModal,
  openCurrencyModal,
  closeCurrencyModal
}