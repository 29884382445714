import gql from 'graphql-tag';

export const addUpdatePricingMutation = gql`
mutation($id: Int, $locationId: Int!, $categoryId: Int!, $subCategoryId: Int!, $isActive: Boolean, 
  $currency: String!, $isPriceEditable: Boolean, $basePrice: Float, $multiplierValue: Float) {
  addUpdatePricing(id: $id, locationId: $locationId,  categoryId: $categoryId, subCategoryId: $subCategoryId,
  isActive: $isActive, currency: $currency, isPriceEditable: $isPriceEditable, basePrice: $basePrice, multiplierValue: $multiplierValue
) {
      status
      errorMessage    
  }
}`;


export const deletePricingMutation = gql`
mutation($id: Int!) {
  deletePricing(id: $id) {
      status
      errorMessage    
  }
}`;


export const updatePricingStatusMutation = gql`
mutation($id: Int!, $isActive: Boolean) {
  updatePricingStatus(id: $id, isActive: $isActive) {
      status
      errorMessage    
  }
}`;