import histroy from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import { addCategoryMutation } from '../../../lib/graphql';

export const addCategory = (
  id,
  name,
  description,
  logoImage,
  bannerImage,
  isPopular,
  isJobPhotoRequired,
  travellingPrice,
  userServiceFeeValue,
  partnerServiceFeeValue,
  pricingType,
  status,
  currency
) => {

  return async (dispatch, getState, { client }) => {
    try {

      dispatch(setLoaderStart('AddCategory'));

      const { data } = await client.mutate({
        mutation: addCategoryMutation,
        variables: {
          id,
          name,
          description,
          logoImage,
          bannerImage,
          isPopular: String(isPopular) === 'true' ? 1 : 0,
          isJobPhotoRequired: String(isJobPhotoRequired) === 'true' ? 1 : 0,
          travellingPrice,
          userServiceFeeValue,
          partnerServiceFeeValue,
          pricingType,
          status,
          currency
        }
      });

      dispatch(setLoaderComplete('AddCategory'));

      if (data?.addCategory?.status === 200) {
        showToaster({ messageId: 'addCatSuccess', requestContent: id, toasterType: 'success' });
        histroy.push('/siteadmin/category');
      } else if (data?.addCategory?.status !== 200) {
        showToaster({ messageId: 'errorMessage', requestContent: data?.addCategory?.errorMessage, toasterType: 'error' });
      }
    } catch (err) {
      showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
    }
  }
};