import gql from "graphql-tag";

export const getCurrencies = gql`
    {
      getCurrencies {
        id
        symbol
        isEnable
        isPayment
        isBaseCurrency
        status
      }
    }
`;

export const getCurrencyRates = gql`{
  getCurrencyRates {
      base
      rates
  }
}`;

export const getAllAdminRolesQuery = gql`{
  getAllAdminRoles {
    results{
      id
      name
      description
      createdAt
      updatedAt
      privileges
    }
  }
}`;

export const createAdminRoleQuery = gql`
  mutation ($id: Int, $name: String!, $description: String, $privileges: [Int]!) {
    createAdminRole (id: $id, name: $name, description: $description, privileges: $privileges) {
        status
        errorMessage
}
}`;

export const deleteAdminQuery = gql`
mutation ($id: Int!) {
  deleteAdminRole(id: $id) {
      status
      errorMessage
  }
}`;

export const getPrivilegesQuery = gql`
query {
  getPrivileges {
      results{
        id
        name
        permittedUrls
      }
      status
      errorMessage
  }
}`;

export const createAdminUserMutation = gql`
mutation ($id: String, $email: String!, $password: String, $roleId: Int!) {
  createAdminUser (id: $id, email: $email, password: $password, roleId: $roleId) {
  status
  errorMessage
  }
}`;

export const deleteAdminUserMutation = gql`
mutation ($id: String!) {
  deleteAdminUser (id: $id) {
  status
  errorMessage
  }
}`;

export const getAdminUserQuery = gql`
query {
  getAdminUser {
      result{
          id
          email
          isSuperAdmin
          roleId
          createdAt
          updatedAt
          adminRole {
              id
              privileges
          }
      }
      status
      errorMessage
  }
}`;


export const getAllAdminUsersQuery = gql`
query {
  getAllAdminUsers {
    results{
      id
      email
      isSuperAdmin
      roleId
      createdAt
      updatedAt
      adminRole {
        id
        name
        description
        createdAt
        updatedAt
        privileges
      }
    }
  }
}`;

export const adminLoginQuery = gql`
query (
  $email: String!,
  $password: String!) {
  adminUserLogin (
      email: $email,
      password: $password
  ) {
      status
      token
      isSuperAdmin
      errorMessage
  }
}`;

export const adminUserLogoutQuery = gql`
query {
    adminUserLogout {
        status
    }
  }`;

export const getSiteSettingsQuery = gql`
  query getSiteSettings ($type: String){
      getSiteSettings(type: $type){
        results{
          name
          value
        }
        status
        errorMessage
      }
    }`;

export const updateHomePageCategoryMutation = gql`
    mutation ($homePageCategoryList: [categoryListInputType]!) {
        updateHomePageCategory (homePageCategoryList: $homePageCategoryList) {
        status
        errorMessage
        }
    }`;

export const updateHomePageCityMutation = gql`
    mutation updateHomePageCity (
        $citySectionTitle1: String
        $citySectionContent1: String
      ) {
        updateHomePageCity (
          citySectionTitle1: $citySectionTitle1
          citySectionContent1: $citySectionContent1
        ) {
          status
          errorMessage
        }}`;


export const updateHomePageFooterMutation = gql`
mutation updateHomePageFooter (
    $footerLogo1: String
    $footerLogo2: String
    $footerLogo3: String
    $footerLogo4: String
    $footerTitle1: String
    $footerContent1: String
    $footerLink1: String
    $footerLink2: String
    $footerLink3: String
    $footerLink4: String
    $footerLinkName1: String
    $footerLinkName2: String
    $footerLinkName3: String
    $footerLinkName4: String
    $footerLinkTitle: String
    $footerBottom: String
  ) {
    updateHomePageFooter (
      footerLogo1: $footerLogo1
      footerLogo2: $footerLogo2
      footerLogo3: $footerLogo3
      footerLogo4: $footerLogo4
      footerTitle1: $footerTitle1
      footerContent1: $footerContent1
      footerLink1: $footerLink1
      footerLink2: $footerLink2
      footerLink3: $footerLink3
      footerLink4: $footerLink4
      footerLinkName1: $footerLinkName1
      footerLinkName2: $footerLinkName2
      footerLinkName3: $footerLinkName3
      footerLinkName4: $footerLinkName4
      footerLinkTitle: $footerLinkTitle
      footerBottom: $footerBottom
    ) {
      status
      errorMessage
    }
  }`;

export const updateHomePageHomeMutation = gql`
mutation updateHomePageHome (
    $homeSectionImage1: String
    $homeSectionImage2: String
    $homeSectionImage3: String
    $homeSectionImage4: String
    $homeSectionImage5: String
    $homeSectionImage6: String
    $homeSectionImage7: String
    $homeSectionImage8: String
    $homeSectionButton1: String
    $homeSectionTitle1: String
  ) {
    updateHomePageHome (
      homeSectionImage1: $homeSectionImage1
      homeSectionImage2: $homeSectionImage2
      homeSectionImage3: $homeSectionImage3
      homeSectionImage4: $homeSectionImage4
      homeSectionImage5: $homeSectionImage5
      homeSectionImage6: $homeSectionImage6
      homeSectionImage7: $homeSectionImage7
      homeSectionImage8: $homeSectionImage8
      homeSectionButton1: $homeSectionButton1
      homeSectionTitle1: $homeSectionTitle1
    ) {
      status
      errorMessage
    }
  }`;

export const updateHomePageSafetyMutation = gql`
mutation updateHomePageSafety (
    $safetyGridImage1: String
    $safetyGridImage2: String
    $safetyGridImage3: String
    $safetyGridTitle1: String
    $safetyGridContent1: String
    $safetyGridLink1: String
    $safetyGridLink2: String
    $safetyGridImage4: String
  ) {
    updateHomePageSafety (
      safetyGridImage1: $safetyGridImage1
      safetyGridImage2: $safetyGridImage2
      safetyGridImage3: $safetyGridImage3
      safetyGridTitle1: $safetyGridTitle1
      safetyGridContent1: $safetyGridContent1
      safetyGridLink1: $safetyGridLink1
      safetyGridLink2: $safetyGridLink2
      safetyGridImage4: $safetyGridImage4
    ) {
      status
      errorMessage
    }
  }`;

export const updateHomePageSignupMutation = gql`
  mutation updateHomePageSignup (
      $signupGridImage1: String
      $signupGridImage2: String
      $signupGridImage3: String
      $signupGridTitle1: String
      $signupGridContent1: String
      $signupGridLink1: String
      $signupGridLink2: String
      $signupGridImage4: String
    ) {
      updateHomePageSignup (
        signupGridImage1: $signupGridImage1
        signupGridImage2: $signupGridImage2
        signupGridImage3: $signupGridImage3
        signupGridTitle1: $signupGridTitle1
        signupGridContent1: $signupGridContent1
        signupGridLink1: $signupGridLink1
        signupGridLink2: $signupGridLink2
        signupGridImage4: $signupGridImage4
      ) {
        status
        errorMessage
      }
    }`;

export const updateMobileSettingsMutation = gql`
    mutation updateMobileSettings(
        $appForceUpdate: String
        $userAndroidVersion: String
        $userIosVersion: String
        $partnerAndroidVersion: String
        $partnerIosVersion: String,
        $stripePublishableKey: String,
        $allowableDistace: String,
        $allowedServices: String
        $notificationInterval: String				
        $sleepPartnerAndroid: String
        $sleepPartnerios: String
        $contactPhoneNumber: String
        $contactEmail: String
        $skype: String,
        $maximumEmergencyContact: String
        $duration: String
        $job: String
        $photo: String
        $description: String
        $estimatedPrice: String,
        $location: String
        $requestTimeTone: String
        $isRequestTimerToneEnable: String
        $openAppOnRequest: String
        $requestToneFile: String,
        $defaultScheduleInterval: String
        $addtionalWorkInterval: String,
        $randomProviderSelection:String
        $preferredProviderSelection:String,
        $maximumProviders:String
        ) {
            updateMobileSettings(
            appForceUpdate: $appForceUpdate
            userAndroidVersion: $userAndroidVersion
            userIosVersion: $userIosVersion
            partnerAndroidVersion: $partnerAndroidVersion
            partnerIosVersion: $partnerIosVersion,
            stripePublishableKey: $stripePublishableKey,
            allowableDistace: $allowableDistace,
            allowedServices: $allowedServices,
            notificationInterval: $notificationInterval,
            sleepPartnerAndroid: $sleepPartnerAndroid,
            sleepPartnerios: $sleepPartnerios,
            contactPhoneNumber: $contactPhoneNumber,
            contactEmail: $contactEmail,
            skype: $skype,
            maximumEmergencyContact: $maximumEmergencyContact,
            duration: $duration,
            job: $job,
            photo: $photo,
            description: $description,
            estimatedPrice: $estimatedPrice,
            location: $location
            requestTimeTone: $requestTimeTone
            isRequestTimerToneEnable: $isRequestTimerToneEnable
            openAppOnRequest: $openAppOnRequest
            requestToneFile: $requestToneFile
            defaultScheduleInterval: $defaultScheduleInterval
            addtionalWorkInterval: $addtionalWorkInterval
            randomProviderSelection:$randomProviderSelection
            preferredProviderSelection:$preferredProviderSelection
             maximumProviders:$maximumProviders
            ){
              status
    errorMessage
            }
        }`;

export const updateToneMutation = gql`
mutation updateTone {
    updateTone {
    status
    }
  }`;

export const updateSiteSettingsMutation = gql`
  mutation updateSiteSettings(
      $siteName: String,
      $siteTitle: String,
      $metaDescription: String, 
      $facebookLink: String,
      $twitterLink: String,
      $instagramLink: String,
      $logoHeight: Int,
      $logoWidth: Int,
      $metaKeyword: String,
      $homeLogo: String,
      $youtubeLink: String,
      $favicon: String,
      ) {
          updateSiteSettings(
          siteName: $siteName
          siteTitle: $siteTitle
          metaDescription: $metaDescription
          facebookLink: $facebookLink
          twitterLink: $twitterLink
          instagramLink: $instagramLink
          logoHeight: $logoHeight
          logoWidth: $logoWidth
          metaKeyword: $metaKeyword
          homeLogo: $homeLogo
          youtubeLink: $youtubeLink
          favicon: $favicon
          ){
          status
          errorMessage
        }
      }` ;



export const updateStaticPageMutation = gql`
      mutation updateStaticPage(
        $id: Int,
        $content: String,
        $metaTitle: String,
        $metaDescription: String,
        $pageBanner: String,
      ) {
        updateStaticPage(
          id: $id,
          content: $content,
          metaTitle: $metaTitle,
          metaDescription: $metaDescription,
          pageBanner: $pageBanner,
        ) {
            status
            errorMessage
        }
      }`;

export const getEditStaticPageQuery = gql`query getEditStaticPage ($id: Int!) {
        getEditStaticPage (id: $id) {
            result{
              id
              pageName
              content
              metaTitle
              metaDescription
              pageBanner
              createdAt
            }  
        }
      }`;


