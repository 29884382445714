import histroy from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import { addSubCategoryMutation } from '../../../lib/graphql';

export const addSubCategory = (
  id,
  name,
  description,
  image,
  categoryId,
  status
) => {

  return async (dispatch, getState, { client }) => {
    try {

      dispatch(setLoaderStart('AddSubCategory'));

      const { data } = await client.mutate({
        mutation: addSubCategoryMutation,
        variables: {
          id,
          name,
          description,
          image,
          categoryId,
          status
        }
      });

      dispatch(setLoaderComplete('AddSubCategory'));

      if (data?.addSubCategory?.status === 200) {
        showToaster({ messageId: 'addSubCatSuccess', requestContent: id, toasterType: 'success' });
        histroy.push('/siteadmin/sub-category');
      } else if (data?.addSubCategory?.status !== 200) {
        showToaster({ messageId: 'errorMessage', requestContent: data?.addSubCategory?.errorMessage, toasterType: 'error' });
      }
    } catch (err) {
      showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
    }
  }
}