import gql from "graphql-tag";

export const getMapViewDataQuery = gql`
query ($id: String!, $period: String!, $limit: Int) {
    getMapViewData(id: $id, period: $period, limit: $limit) {
        status
        results {
          id
          userType
          isActive
          activeStatus
          profile {
            lat
            lng
          }
      }
    }
  }`;

export const getHeatMapDataQuery = gql`
query ($id: String!, $period: String!, $limit: Int) {
    getHeatMapData(id: $id, period: $period, limit: $limit) {
      status
        results {
          id
          userType
          profile {
            lat
            lng
          }
        }
        bookingResults{
          id
          userLocationLat
          userLocationLng
        }
    }
  }`;