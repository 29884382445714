import { convert } from "./currencyConvertion";

export  function earningConverter({ earnings, base, rates }) {
    const todayEarningsValue = earningsTotal({ earnings: earnings?.result?.todayEarnings, base, rates });
    const weeklyEarningsValue = earningsTotal({ earnings: earnings?.result?.weeklyEarnings, base, rates });
    const monthlyEarningsValue = earningsTotal({ earnings: earnings?.result?.monthlyEarnings, base, rates });
    const totalEarningsValue = earningsTotal({ earnings: earnings?.result?.totalEarnings, base, rates });
    return {
        todayEarningsValue,
        weeklyEarningsValue,
        monthlyEarningsValue,
        totalEarningsValue
    };
}

export function earningsTotal({ earnings, base, rates }) {
    return earnings?.reduce(
        function (a, b) {
            if (b.currency != base) {
                return a + convert(base, rates, parseFloat(b.userServiceFee), b.currency) + convert(base, rates, parseFloat(b.partnerServiceFee), b.currency)
            } else {
                return a + parseFloat(b.userServiceFee) + parseFloat(b.partnerServiceFee)
            }
        }, 0
    ).toFixed(2);
}