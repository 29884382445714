const errorMessage = (errorcode, error) => {

    const message = {
        loginError: "Oops! Please login with your account and try again.",
        isAdminRoleExist: 'Sorry, unable to find this admin role.',
        commonError: 'Oops! Something went wrong. Please try again.',
        catchError: 'Oops! Something went wrong.' + error,
        checkAdminRoleUsed: 'Oops! Unable to delete the admin role due to some admin user using this role.',
        invalidRole: 'Oops, it looks like the chosen role is not valid. Please try with different valid role.',
        emailAlreadyExist: 'Oops! this email address is already exist.',
        userLogin: 'Oops! Please login and continue.',
        invalidError: 'Something went wrong!',
        checkLogin: 'You haven\'t authorized for this action.',
        choosePaymentMethod: 'Atleast one payment method option must be active.',
        enableStripePayment: 'Please enable Stripe Payment to activate the wallet.',
        updateStatusError: "Oops! Couldn't update status!",
        login: 'Please login with your account and continue.',
        unableToInactiveFareforCategory: "Sorry, unable to inactive. The chosen category is used on the manage fare. Please remove the fare and try again.",
        unableToInactiveFareforLocation: "Sorry, unable to inactive. The chosen location is used on the manage fare. Please remove the fare and try again.",
        isCategoryUsed: "Unable to delete this Category  because it is being used by its Sub Categories. Please remove the Sub Category and then try again.",
        isURLUsed: "The page URL already exist!",
        invalidRequest: "Oops! Invalid request",
        checkPreferredCurrency: 'Oops! This currency is used as the preferred currency.',
        findValidCurrency: 'Sorry, Please add the valid currency!',
        currencyAlreadyExist: 'Sorry, it looks like the currency is already exist.',
        geoLocation: 'Geo location is now drawn completely. Try zooming the map and draw closed location boundary.',
        unableToDeleteFareforLocation: "Sorry, unable to delete. The chosen location is used on the manage fare. Please remove the fare and try again.",
        fareAlreadyExist: 'Sorry, it looks like the fare is already exist for the chosen location and category.',
        unableToCreateFare: 'Sorry, unable to create a fare for the chosen location and category.',
        checkBookingExist: 'Sorry, The chosen pricing is having the bookings and unable to disable it.',
        isPromoCodeExist: 'Oops! It looks like already this promo code is used. Please try again with different promo code name.',
        unableToCreateCode: 'Oops! Something went wrong! Unable to create a new promo code. Please try again.',
        unableToDeleteCode: "Oops! something went wrong. Unable to delete the promo code.",
        unableToUpdateCode: 'Oops! Something went wrong! Unable to update the promo code. Please try again.',
        unableToDeleteFareForCategory: "Sorry, unable to delete. The chosen category is used on the manage fare. Please remove the fare and try again.",
        deletedUser: 'Oops! We are unable to find your account. Please contact support for help.',
        unableToDeletePartnerBooking: "Oops! it looks like the service provider has an active booking and so we are unable to delete them right now.",
        updateDocumentError: "Oops! Couldn't update document!",
        checkUserEmailExist: 'Oops, the provided email is already exits with the other user.',
        checkPhoneNumberExist: 'Oops, the provided phone number is already exits with the other user.',
        checkUserBan: "Can't Change the status as currently, they are doing service, Please try again later",
        unableToUpdateUserDetails: 'Oops! Something went wrong, unable to update the user information.',
        adminLogin: 'Please login as an admin and continue the action.',
        unableToDeleteUserBooking: "Oops! it looks like the user has an active booking and so we are unable to delete them right now.",
        unableToCreateAdminUser: "Oops, something went wrong. Unable to create an admin user.",
        noRecords: "No records found.",
        invalidPassword: "Wrong Password. Please try again",
        checkUserEmail: "No account exists for this email. Make sure it's typed in correctly, or “sign up” instead",
        userAlreadyLoggedIn: "You are already logged in!",
        noResults: 'No result found',
        unableToFindBookingDetails: "Oops! Unable to find the booking information.",
        updateExistingCart: 'Your cart has been cleared since the admin has made some changes on the admin panel settings.',
        checkAdminLoggedIn: 'Something went wrong, Please login.',
        checkUserLogin: "Please login for this action.",
        noCategoryFound: 'No category found',

        //Toaster Error Message
        success: 'Success',
        error: 'Error!',
        warning: 'Warning',
        errorMessage: error || "Oops! something went wrong! Please try again.",
        catchMessage: "Oops! Something went wrong! " + error,
        addPromoCodeSuccess: `Promo Code has been ${error ? 'updated' : 'added'} successfully.`,
        deletePromoCodeSuccess: `Promo Code has been deleted successfully.`,
        siteSettingsSuccess: `The site settings have been updated successfully!`,
        adminUserSuccess: `Admin User has been ${error ? 'updated' : 'added'} successfully.`,
        adminDeleteSuccess: `Admin Role has been deleted successfully!`,
        adminRoleSuccess: `Admin Role has been ${error ? 'updated' : 'added'} successfully.`,
        delUser: `${error === 2 ? 'The provider' : 'The user'} has been deleted successfully.`,
        adminUserDeleteSuccess: `Admin User has been deleted successfully!`,
        deleteContentSuccess: `Changes are updated!`,
        updateStatusSuccess: `Changes are updated!`,
        editPartnerSuccess: `Service Provider has been updated!`,
        updateHomeSuccess: `Changes are updated!`,
        updateCitySuccess: `Homepage settings changes have been successfully applied!`,
        updateFooterSuccess: `Homepage settings changes have been successfully applied!`,
        mobileSettingsSuccess: `The mobile app settings have been updated successfully!`,
        updateProfileSuccess: `Profile image has been uploaded!`,
        removeCancelSuccess: `Cancel Reason has been deleted successfully.`,
        removeCatSuccess: `The category has been deleted successfully.`,
        failedCurrency: `Sorry, you can't disable the base currency. Try to set a different base currency and disable this one.`,
        successCurrency: `Currency status has changed.`,
        baseCurrency: `Base Currency is set successfully.`,
        allowedCurrency: `Allowed Payment Currency Success.`,
        addCurrency: `Currency has been added successfully.`,
        deleteCurrency: `Currency has been deleted successfully.`,
        failedDeleteCurrency: `Sorry, you can't delete the base currency. Try to set a different base currency and delete this one.`,
        userExp: `User experience has been uploaded!`,
        removeDoc: ` ${error} document has been removed!`,
        userIdentity: `User Identity has been uploaded!`,
        selectLoc: `Please select location!`,
        addLoc: `The Location has been added successfully!`,
        updateLoc: `The Location has been updated successfully!`,
        delLoc: `The Location has been deleted successfully!`,
        updateCashPayout: `Updated status for Cash Payout!`,
        updatePayout: `Updated status!`,
        delFareSuccess: `The fare has been deleted successfully.`,
        statusFareSuccess: `The status has been updated successfully.`,
        updateSms: `Sms method has been updated successfully.`,
        addSubCatSuccess: `The sub category has been ${error ? 'updated' : 'added'} successfully.`,
        removeSubCatSuccess: `The sub category has been deleted successfully.`,
        statusSubCatSuccess: `The sub category status has been updated successfully.`,
        updateUser: `User has been updated!`,
        passwordChange: `Admin details changed successfully!`,
        limitError: `Try with smallest size image.`,
        filetypeError: `You are trying to upload invalid image file. Please upload PNG, JPG & JPEG format image file.`,
        categoryLogo: `Category Logo Image is required.`,
        categoryBanner: `Category Banner Image is required.`,
        subCategoryImage: `SubCategory Image is required.`,
        noCategory: `No categories found.`,
        pleaseUpload: `Please upload image.`,
        addCmsContent: `Please Add  Content.`,
        timer: `Please add timer tone.`,
        addDescription: `Please Add  Description.`,
        pushNotificationSent: `Push notification has been sent.`,
        tempImage: `${error ? 'Your Image has been uploaded.' : 'Your Image has been removed.'}`,
        addCancelSuccess: `Cancel Reason has been ${error ? 'updated' : 'added'} successfully.`,
        addCatSuccess: `The category has been ${error ? 'updated' : 'added'} successfully.`,
        addFareSuccess: `The fare has been ${error ? 'updated' : 'added'} successfully.`,
        updateConfigSettings: `Configurations updated successfully!`,
        updatePaymentStatus: `Payment gateway status has been updated successfully.`,
        updateStatusFailed: `Failed to update status!`,
        paymentStatusFailed: error ? error : 'Failed to update status!'

    }
    return message[errorcode];
}
export default errorMessage;