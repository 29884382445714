import React from 'react';
import PropTypes from 'prop-types';
import s from './ConfigSettingsForm.css';
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  Container

} from 'react-bootstrap';

import { Field, reduxForm } from 'redux-form';

import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import submit from './submit'
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
import { connect } from 'react-redux';
import validate from './validate';
import Loader from '../../Common/Loader/Loader';
import bt from '../../../components/commonStyle.css';


class ConfigSettingsForm extends React.Component {

  static propTypes = {
    initialValues: PropTypes.object,
    title: PropTypes.string.isRequired,
  };


  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.formGroup}>
          <div>
            <label className={cx(bt.labelText, s.removeBold, s.descriptionText)} >{label}</label>
          </div>
          <div>
            <FormControl {...input} placeholder={label} type={type} className={bt.formControlInput} maxlength={maxlength} />
            {touched && error && <span className={cx(bt.errorMessage, 'errorRTL')}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  };

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.formGroup}>
          <div>
            <label className={cx(bt.labelText, s.removeBold, s.descriptionText)} >{label}</label>
          </div>
          <div>
            <FormControl
              {...input}
              className={className}
              placeholder={label}
              as="textarea"
              rows="3"
            >
              {children}
            </FormControl>
            {touched && error && <span className={cx(bt.errorMessage, 'errorRTL')}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  };

  render() {
    const { error, handleSubmit, submitting } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <Container fluid>
          <Row>
            <div className={cx(s.heading, 'textAlignRightRTL', 'textWhite')}>{formatMessage(messages.manageSiteConfig)}</div>
            <Col xs={12} sm={12} md={12} lg={12}>
              <form onSubmit={handleSubmit(submit)}>
                {error && <strong>{error}</strong>}
                <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.stripeSettings)}</h5>
                <div className={s.girdOne}>
                  <Field name="stripePublishableKey" type="text" component={this.renderFormControl} label={formatMessage(messages.stripePublishableKey)} />
                </div>
                <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.smtpSettings)}</h5>
                <div className={s.girdTwo}>
                  <Field name="smtpHost" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpHost)} />
                  <Field name="smtpPort" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpPort)} />
                  <Field name="smptEmail" type="text" component={this.renderFormControl} label={formatMessage(messages.smptEmail)} />
                  <Field name="smtpSender" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpSender)} />
                  <Field name="smtpSenderEmail" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpSenderEmail)} />
                  <Field name="smtpPassWord" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpPassWord)} />
                </div>
                <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.pushNotificationSettings)}</h5>
             
                <div className={s.girdTwo}>
                  <Field name="fcmPushNotificationKey" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.fcmPushNotificationKey)} />
                </div>
                <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.otherSettings)}</h5>
                <div className={s.girdOne}>
                  <Field name="maxUploadSize" type="text" component={this.renderFormControl} label={formatMessage(messages.maxUploadLabel)} />
                </div>
                <div className={cx(bt.textAlignRight, 'textAlignLeftRTL', 'loadingBtnRTL')}>
                  <FormGroup className={s.formGroup}>
                    <div>
                      <Loader
                        type={"button"}
                        label={formatMessage(messages.submitButton)}
                        show={submitting}
                        buttonType={'submit'}
                        className={cx(bt.btnPrimary, bt.clickBtn)}
                        disabled={submitting}
                        isSuffix={true}
                      />
                    </div>
                  </FormGroup>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div >
    );
  }
}

ConfigSettingsForm = reduxForm({
  form: 'ConfigSettingsForm', // a unique name for this form
  validate,
})(ConfigSettingsForm);

const mapState = (state) => ({

});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ConfigSettingsForm)));

