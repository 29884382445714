import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Nav, NavDropdown } from 'react-bootstrap';
import cx from 'classnames';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';

import Link from '../Link';
import HeaderModal from '../HeaderModal/HeaderModal';

import history from '../../history';
import { formatLocale } from '../../helpers/formatLocale';
import { openHeaderModal } from '../../actions/siteadmin/modalActions';
import { adminLogout } from '../../actions/siteadmin/logout';
import { choseToTheme } from '../../actions/currency/getCurrencyRates';
import messages from '../../locale/messages';

import LogOutIcon from '../../../public/Icons/logout.svg';
import MainSiteIcon from '../../../public/Icons/mainSite.svg';
import CheckUserStatusQuery from './getCheckUserStatus.graphql';
import LanguageIcon from '../../../public/Icons/languageIcon.svg';
import selectedLightIcon from '../../../public/Icons/lightModeOn.svg';
import selectedDarkIcon from '../../../public/Icons/darkModeOn.svg';
import dropDownLightIcon from '../../../public/Icons/lightModeOff.svg';
import dropDownDarkIcon from '../../../public/Icons/darkModeOff.svg';

import s from './AdminHeader.css';

class AdminHeader extends React.Component {
  static defaultProps = {
    checkLoginUserExist: {
      userExistloading: true,
      getAdminUserExists: {
        userExistStatus: null,
      },
    },
  };

  constructor(props) {
    super(props);
  }

  navigation = URL => {
    if (URL) {
      history.push(URL);
    }
  };

  handleChange = e => {
    const { openHeaderModal } = this.props;
    openHeaderModal('languageModal');
  };

  goToMainSite = e => {
    e.preventDefault();
    history.push('/');
  };

  render() {
    const {
      openHeaderModal,
      currentLocale,
      adminLogout,
      selectedTheme,
      choseToTheme,
    } = this.props;
    const {
      checkLoginUserExist: { userExistloading, getAdminUserExists },
    } = this.props;

    if (!userExistloading && getAdminUserExists) {
      if (getAdminUserExists.userExistStatus) {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
          adminLogout();
          window.location.reload();
        }
      }
    }

    return (
      <div className={cx(s.headerBg, 'bgBlackTwo', 'mainMenu')}>
        <div className={cx(s.logOut, 'logOut', 'floatLeftRTL')}>
          {/* <Navbar expand="lg"> */}
          <Nav className="mr-auto">
            <NavDropdown
              title={
                <>
                  {selectedTheme && selectedTheme === 'light' && (
                    <span className={s.selectedThemeColor}>
                      {' '}
                      <img src={selectedLightIcon} alt="" />{' '}
                      <FormattedMessage {...messages.lightMode} />
                    </span>
                  )}
                  {selectedTheme && selectedTheme === 'dark' && (
                    <span className={s.selectedThemeColorDark}>
                      {' '}
                      <img src={selectedDarkIcon} alt="" />{' '}
                      <FormattedMessage {...messages.darkMode} />
                    </span>
                  )}
                </>
              }
              noCaret
              id="basic-nav-dropdown1"
              className={cx(
                s.nonBreakPointScreen,
                'adminThemeHeader',
                'd-none  d-md-none d-lg-block d-xl-block',
              )}
            >
              <NavDropdown.Item
                to="#"
                onClick={() => {
                  choseToTheme('light');
                }}
                className={cx(s.themeIconDropDownLink, s.borderFirst)}
              >
                <div
                  className={cx(
                    { [s.selectedThemeColor]: selectedTheme === 'light' },
                    s.themeIconSec,
                  )}
                >
                  {selectedTheme && selectedTheme === 'light' ? (
                    <img
                      src={selectedLightIcon}
                      alt=""
                      className={cx(s.themeIcon, 'themeIconRTL')}
                    />
                  ) : (
                    <img
                      src={dropDownLightIcon}
                      alt=""
                      className={cx(s.themeIcon, 'themeIconRTL')}
                    />
                  )}
                  <FormattedMessage {...messages.lightMode} />
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item
                to="#"
                onClick={() => {
                  choseToTheme('dark');
                }}
                className={s.themeIconDropDownLink}
              >
                <div
                  className={cx(
                    { [s.selectedThemeColorDark]: selectedTheme === 'dark' },
                    s.themeIconSec,
                  )}
                >
                  {selectedTheme && selectedTheme === 'dark' ? (
                    <img
                      src={selectedDarkIcon}
                      alt=""
                      className={cx(s.themeIcon, 'themeIconRTL')}
                    />
                  ) : (
                    <img
                      src={dropDownDarkIcon}
                      alt=""
                      className={cx(s.themeIcon, 'themeIconRTL')}
                    />
                  )}
                  <FormattedMessage {...messages.darkMode} />
                </div>
              </NavDropdown.Item>
            </NavDropdown>
            <Link
              noLink
              onClick={e => this.handleChange(e)}
              className={cx(
                s.siteColor,
                s.cursurPointer,
                'textWhite',
                'languageHeaderRTL',
              )}
            >
              <span className={cx(s.iconWidth, s.languageIcon, 'svgImg')}>
                <img
                  src={LanguageIcon}
                  className={cx(s.sideMenuIcon, s.noFilter)}
                />
              </span>
              <span className={cx(s.iconTextPadding, 'iconTextPaddingRTL')}>
                {formatLocale(currentLocale)}
              </span>
            </Link>
            <Link
              onClick={this.goToMainSite}
              className={cx(s.Sitepadding, s.siteColor, 'textWhite')}
            >
              <span
                className={cx(
                  s.displayInlineBlock,
                  s.vtrTop,
                  s.iconWidth,
                  'svgImg',
                )}
              >
                <img
                  src={MainSiteIcon}
                  className={cx(s.sideMenuIcon, s.noFilter)}
                />
              </span>
              <span
                className={cx(
                  s.displayInlineBlock,
                  s.vtrMiddle,
                  s.iconTextPadding,
                  'iconTextPaddingRTL',
                )}
              >
                <FormattedMessage {...messages.goToMainSite} />
              </span>
            </Link>
            <Link
              to="/login"
              onClick={() => adminLogout()}
              className={cx(s.siteColor, 'textWhite')}
            >
              <span
                className={cx(
                  s.displayInlineBlock,
                  s.vtrTop,
                  s.iconWidth,
                  'svgImg',
                )}
              >
                <img
                  src={LogOutIcon}
                  className={cx(s.sideMenuIcon, s.noFilter)}
                />
              </span>
              <span
                className={cx(
                  s.displayInlineBlock,
                  s.vtrMiddle,
                  s.iconTextPadding,
                  'iconTextPaddingRTL',
                )}
              >
                <FormattedMessage {...messages.logout} />
              </span>
            </Link>
          </Nav>
          <HeaderModal />
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  currentLocale: state.intl.locale,
  selectedTheme: state.currency.theme,
});
const mapDispatch = {
  openHeaderModal,
  adminLogout,
  choseToTheme,
};

// export default withStyles(s)(connect(mapState, mapDispatch)(AdminHeader));
export default compose(
  injectIntl,
  withStyles(s),
  graphql(CheckUserStatusQuery, {
    name: 'checkLoginUserExist',
    options: {
      ssr: false,
      pollInterval: 60000, // 1 minute
    },
  }),
  connect(mapState, mapDispatch),
)(AdminHeader);
