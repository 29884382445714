import gql from 'graphql-tag';
import { change } from 'redux-form';
import {
	MOBILE_SETTINGS_UPLOAD_START,
	MOBILE_SETTINGS_UPLOAD_ERROR,
	MOBILE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index'
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import { updateMobileSettingsMutation, updateToneMutation } from '../../lib/graphql';
import showToaster from '../../helpers/toastrMessgaes/showToaster';
import showErrorMessage from '../../helpers/showErrorMessage';
import { api } from '../../config';

export const updateMobileSettings = ({
	appForceUpdate,
	userAndroidVersion,
	userIosVersion,
	partnerAndroidVersion,
	partnerIosVersion,
	stripePublishableKey,
	allowableDistace,
	allowedServices,
	notificationInterval,
	sleepPartnerAndroid,
	sleepPartnerios,
	contactPhoneNumber,
	contactEmail,
	skype,
	maximumEmergencyContact,
	duration,
	job,
	photo,
	description,
	estimatedPrice,
	location,
	requestTimeTone,
	isRequestTimerToneEnable,
	openAppOnRequest,
	requestToneFile,
	defaultScheduleInterval,
	addtionalWorkInterval,
	randomProviderSelection,
	preferredProviderSelection,
	maximumProviders
}) => {

	try {
		return async (dispatch, getState, { client }) => {
			dispatch({ type: MOBILE_SETTINGS_UPLOAD_START });

			dispatch(setLoaderStart('MobileSettings'));

			const { data } = await client.mutate({
				mutation: updateMobileSettingsMutation,
				variables: {
					appForceUpdate,
					userAndroidVersion,
					userIosVersion,
					partnerAndroidVersion,
					partnerIosVersion,
					stripePublishableKey,
					allowableDistace,
					allowedServices,
					notificationInterval,
					sleepPartnerAndroid,
					sleepPartnerios,
					contactPhoneNumber,
					contactEmail,
					skype,
					maximumEmergencyContact,
					duration,
					job,
					photo,
					description,
					estimatedPrice,
					location,
					requestTimeTone,
					isRequestTimerToneEnable,
					openAppOnRequest,
					requestToneFile,
					defaultScheduleInterval,
					addtionalWorkInterval,
					randomProviderSelection,
					preferredProviderSelection,
					maximumProviders
				}
			});

			dispatch(setLoaderComplete('MobileSettings'));

			if (data?.updateMobileSettings?.status == 200) {
				dispatch({ type: MOBILE_SETTINGS_UPLOAD_SUCCESS });
				showToaster({ messageId: 'mobileSettingsSuccess', toasterType: 'success' });
			}
			else {
				let errorMessage = data?.updateMobileSettings?.errorMessage || showErrorMessage({ errorCode: 'invalidError' });
				dispatch({ type: MOBILE_SETTINGS_UPLOAD_ERROR });
				showToaster({ messageId: 'errorMessage', requestContent: errorMessage, toasterType: 'error' });
			}
		}
	}
	catch (error) {
		dispatch({ type: MOBILE_SETTINGS_UPLOAD_ERROR });
		showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
	}
}

export const deleteTone = (fileName) => {
	return async (dispatch, getState, { client }) => {

		try {
			dispatch({
				type: MOBILE_SETTINGS_UPLOAD_START
			})

			dispatch(change("MobileSettingsForm", "requestTimeTone", null));

			const url = api.apiEndpoint + "/deleteTone";
			const resp = await fetch(url, {
				method: 'post',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ fileName }),
			});

			const { status } = await resp.json();

			if (status == 200) {

				const { data } = await client.mutate({
					mutation: updateToneMutation
				})

				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_SUCCESS
				})

			} else {
				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_ERROR
				})
			}

		} catch (err) {
			dispatch({
				type: MOBILE_SETTINGS_UPLOAD_ERROR
			})
			showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
			return true;
		}
	}
}