import { api } from '../../../config';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import { updatePrecautionNotificationMutation } from '../../../lib/graphql';

const updatePrecautionNotification = ({ id, title, description, isEnabled, imageName }) => {

    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.mutate({
                mutation: updatePrecautionNotificationMutation,
                variables: {
                    id,
                    title,
                    description,
                    isEnabled: isEnabled === 'true',
                    imageName
                }
            });

            if (data?.updatePrecautionNotification?.status == 200) {
                showToaster({ messageId: 'updateStatusSuccess', toasterType: 'success' });
            } else {
                showToaster({ messageId: 'errorMessage', requestContent: data?.updatePrecautionNotification?.errorMessage, toasterType: 'error' });
            }
        }
        catch (error) {
            showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
        }
    };
}
const removeStaticBannerImage = async (fileName) => {

    try {

        const url = api.apiEndpoint + "/deleteStaticBannerImage";
        const resp = await fetch(url, {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fileName }),
        });

        const { status } = await resp.json();

        if (status) {
            return {
                status
            };
        }

    } catch (err) {
        showToaster({ messageId: 'catchMessage', requestContent: err, toasterType: 'error' });
    }
}

export { removeStaticBannerImage, updatePrecautionNotification }