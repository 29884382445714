import gql from "graphql-tag";

export const deleteUserMutation = gql`
mutation deleteUser($profileId: Int) {
    deleteUser(profileId: $profileId) {
      status
      errorMessage
    }
  }`;

export const getAllUsersQuery = gql`
query getAllUsers($currentPage: Int, $userType: Int){
    getAllUsers(currentPage: $currentPage, userType: $userType){
       count
        results{
          email
            phoneNumber
            userStatus
            isBan
              createdAt
              profile{
                 profileId
                firstName
                lastName
                country
              }
        }
      }
    }`;

export const updateUserMutation = gql`
    mutation updateUser(
        $id: ID,
        $firstName: String,
        $lastName: String,
        $email: String,
        $phoneDialCode: String
        $phoneNumber: String,
        $userStatus: String,
        $isBan: Int
        $phoneCountryCode: String
        ) {
          updateUser(
          id: $id
          firstName: $firstName
          lastName: $lastName
          email:$email
          phoneDialCode: $phoneDialCode
          phoneNumber: $phoneNumber
          userStatus: $userStatus
          isBan: $isBan
          phoneCountryCode: $phoneCountryCode
        )
        {
          status
          errorMessage
        }
      }`;

export const updatePartnerMutation = gql`
      mutation updatePartner(
          $id: ID,
          $firstName: String,
          $lastName: String,
          $email: String,
          $phoneDialCode: String
          $phoneNumber: String,
          $userStatus: String,
          $isBan: Int,
          $phoneCountryCode: String
          ) {
            updatePartner(
            id: $id
            firstName: $firstName
            lastName: $lastName
            email:$email
            phoneDialCode: $phoneDialCode
            phoneNumber: $phoneNumber
            userStatus: $userStatus
            isBan: $isBan
            phoneCountryCode: $phoneCountryCode
          )
          {
            status
            errorMessage
          }
        }`;

export const uploadProfileImageMutation = gql`
        mutation uploadProfileImage($userId:ID,$picture:String){
            uploadProfileImage(userId:$userId, picture:$picture) {
              status
            }
          }`;