import {
  ADD_UPDATE_PRICING_START,
  ADD_UPDATE_PRICING_SUCCESS,
  ADD_UPDATE_PRICING_ERROR
} from '../../../constants';
import { addUpdatePricingMutation } from '../../../lib/graphql';
import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import showErrorMessage from '../../../helpers/showErrorMessage';


export const addUpdatePricing = (
  id,
  locationId,
  categoryId,
  subCategoryId,
  isActive,
  currency,
  isPriceEditable,
  basePrice,
  multiplierValue
) => {
  return async (dispatch, getState, { client }) => {
    try {
      dispatch({
        type: ADD_UPDATE_PRICING_START,
        payload: {
          pricingLoading: true
        }
      });

      dispatch(setLoaderStart('PricingForm'));

      const { data } = await client.mutate({
        mutation: addUpdatePricingMutation,
        variables: {
          id,
          locationId,
          categoryId,
          subCategoryId,
          isActive,
          currency,
          isPriceEditable,
          basePrice,
          multiplierValue
        }
      });

      if (data?.addUpdatePricing?.status === 200) {

        history.push('/siteadmin/pricing/list');
        showToaster({ messageId: 'addFareSuccess', requestContent: id, toasterType: 'success' });

        await dispatch({
          type: ADD_UPDATE_PRICING_SUCCESS,
          payload: {
            pricingLoading: false
          }
        });

        dispatch(setLoaderComplete('PricingForm'));

        return await {
          status: 200
        };
      } else {
        showToaster({ messageId: 'errorMessage', requestContent: data?.addUpdatePricing?.errorMessage, toasterType: 'error' });
        await dispatch({
          type: ADD_UPDATE_PRICING_ERROR,
          payload: {
            pricingLoading: false,
            error: data && data.addUpdatePricing && data.addUpdatePricing.errorMessage
          }
        });

        dispatch(setLoaderComplete('PricingForm'));

        return await {
          status: 400
        };
      }
    } catch (error) {
      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });

      await dispatch({
        type: ADD_UPDATE_PRICING_ERROR,
        payload: {
          pricingLoading: false,
          error: showErrorMessage({ errorCode: 'catchError', error })
        }
      });

      dispatch(setLoaderComplete('PricingForm'));

      return await {
        status: 400
      };
    }
  }
}