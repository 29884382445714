import {
	CREATE_ADMIN_USER_START,
	CREATE_ADMIN_USER_SUCCESS,
	CREATE_ADMIN_USER_ERROR,
	DELETE_ADMIN_USER_START,
	DELETE_ADMIN_USER_SUCCESS,
	DELETE_ADMIN_USER_ERROR,
	GET_ADMIN_USER_START,
	GET_ADMIN_USER_SUCCESS,
	GET_ADMIN_USER_ERROR
} from '../../../constants';
import {
	getPrivilegesQuery,
	createAdminUserMutation,
	deleteAdminUserMutation,
	getAdminUserQuery,
	getAllAdminUsersQuery
} from '../../../lib/graphql';
import { setRuntimeVariable } from '../../runtime';
import { closeAdminUserModal } from '../modalActions';
import showToaster from '../../../helpers/toastrMessgaes/showToaster';

const createAdminUser = (id, email, password, roleId) => {
	return async (dispatch, getState, { client }) => {

		try {
			await dispatch({
				type: CREATE_ADMIN_USER_START,
				payload: {
					createAdminUserLoading: true
				}
			});

			const { data } = await client.mutate({
				mutation: createAdminUserMutation,
				variables: {
					id,
					email,
					password,
					roleId
				},
				refetchQueries: [{ query: getAllAdminUsersQuery }]
			});

			if (data?.createAdminUser?.status === 200) {
				await dispatch({
					type: CREATE_ADMIN_USER_SUCCESS,
					payload: {
						createAdminUserLoading: false
					}
				});
				dispatch(closeAdminUserModal());
				showToaster({ messageId: 'adminUserSuccess', requestContent: id, toasterType: 'success' });
			} else {
				showToaster({ messageId: 'errorMessage', requestContent: data?.createAdminUser?.errorMessage, toasterType: 'error' });
				await dispatch({
					type: CREATE_ADMIN_USER_ERROR,
					payload: {
						createAdminUserLoading: false,
						error: data?.createAdminUser?.errorMessage
					}
				});
			}
		} catch (error) {
			showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
			await dispatch({
				type: CREATE_ADMIN_USER_ERROR,
				payload: {
					createAdminUserLoading: false,
					error
				}
			});
		}
	}
}

const deleteAdminUser = (id) => {
	return async (dispatch, getState, { client }) => {

		try {
			await dispatch({
				type: DELETE_ADMIN_USER_START,
				payload: {
					deleteAdminUserLoading: true
				}
			});

			const { data } = await client.mutate({
				mutation: deleteAdminUserMutation,
				variables: {
					id
				},
				refetchQueries: [{ query: getAllAdminUsersQuery }]
			});

			if (data?.deleteAdminUser?.status === 200) {
				await dispatch({
					type: DELETE_ADMIN_USER_SUCCESS,
					payload: {
						deleteAdminUserLoading: false
					}
				});
				dispatch(closeAdminUserModal());
				showToaster({ messageId: 'adminUserDeleteSuccess', toasterType: 'success' });
			} else {
				showToaster({ messageId: 'errorMessage', requestContent: data?.deleteAdminUser?.errorMessage, toasterType: 'error' });
				await dispatch({
					type: DELETE_ADMIN_USER_ERROR,
					payload: {
						deleteAdminUserLoading: false,
						error: data?.deleteAdminUser?.errorMessage
					}
				});
			}
		} catch (error) {
			showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });

			await dispatch({
				type: DELETE_ADMIN_USER_ERROR,
				payload: {
					deleteAdminUserLoading: false,
					error
				}
			});
		}
	}
}

const getAdminUser = () => {
	return async (dispatch, getState, { client }) => {

		try {
			let privileges, privilegesResult, defaultPrivileges;

			const privilegesData = await client.query({
				query: getPrivilegesQuery
			});

			privilegesResult = privilegesData?.data?.getPrivileges?.results;
			defaultPrivileges = privilegesResult && privilegesResult.length > 0 && privilegesResult.map((item) => item.id);

			await dispatch({
				type: GET_ADMIN_USER_START,
				payload: {
					getAdminUserLoading: true
				}
			});

			const { data } = await client.query({
				query: getAdminUserQuery,
				fetchPolicy: 'network-only'
			});

			if (data?.getAdminUser?.result?.id) {
				dispatch(setRuntimeVariable({
					name: 'isSuperAdmin',
					value: data?.getAdminUser?.result?.isSuperAdmin
				}));

				if (data.getAdminUser.result.isSuperAdmin) {
					privileges = defaultPrivileges;
				} else {
					privileges = data?.getAdminUser?.result?.adminRole?.privileges || []
				}

				await dispatch({
					type: GET_ADMIN_USER_SUCCESS,
					payload: {
						getAdminUserLoading: false,
						id: data?.getAdminUser?.result?.id,
						email: data?.getAdminUser?.result?.email,
						isSuperAdmin: data?.getAdminUser?.result?.isSuperAdmin,
						roleId: data?.getAdminUser?.result?.roleId,
						privileges
					}
				});

			} else {
				await dispatch({
					type: GET_ADMIN_USER_ERROR,
					payload: {
						getAdminUserLoading: false,
						error: data?.getAdminUser?.errorMessage
					}
				});
				return false;
			}
		} catch (error) {
			await dispatch({
				type: GET_ADMIN_USER_ERROR,
				payload: {
					getAdminUserLoading: false,
					error
				}
			});
			return false;
		}
	}
}

export { createAdminUser, deleteAdminUser, getAdminUser }