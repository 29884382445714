import gql from "graphql-tag";

export const updateSmsMethodMutation = gql`
mutation( 
  $id: Int!
  $status: String
  $accountId: String 
  $securityId: String
  $phoneNumber: String
  $phoneDialCode: String
  $phoneCountryCode: String
  $updateStatus: Boolean
) {
  updateSmsMethod(
      id: $id,
      status: $status,
      accountId: $accountId,
      securityId: $securityId,
      phoneNumber: $phoneNumber,
      phoneDialCode: $phoneDialCode,
      phoneCountryCode: $phoneCountryCode,
      updateStatus: $updateStatus
  ) {
      status
      errorMessage
  }
}`;