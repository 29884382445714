import React from 'react';
import { Table } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import bt from '../../../components/commonStyle.css';

import cx from 'classnames';
import s from './PaymentGatewayList.css';
import withStyles from 'isomorphic-style-loader/withStyles';
import messages from '../../../locale/messages';
import { updatePaymentMethod } from "../../../actions/siteadmin/Payout/updatePayout";

class PaymentGatewayList extends React.Component {

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
    }

    async handleChange(e, id) {
        const { updatePaymentMethod } = this.props;
        let status = e.target.value == 'true' ? true : false;
        await updatePaymentMethod(id, status);
    }

    render() {
        const { getPaymentMethods } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div className={cx(s.widthInner, 'whiteDropdown', s.paymentInner)}>
                <div class="clearfix"></div>
                <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewResponsiveTable', 'topScrollbarTable', 'paymentTable')}>
                    <Table className={cx("table", 'topScrollbarTableContent')}>
                        <thead>
                            <tr>
                                <th scope="col"><FormattedMessage {...messages.id} /></th>
                                <th scope="col"><FormattedMessage {...messages.paymentGateWay} /></th>
                                <th scope="col"><FormattedMessage {...messages.status} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getPaymentMethods && getPaymentMethods.results && getPaymentMethods.results.length > 0 && getPaymentMethods.results.map((item, index) => {
                                    let status = item.isEnable ? 'true' : 'false';
                                    return (
                                        <tr key={index}>
                                            <td data-label={formatMessage(messages.id)}>{item && item.id}</td>
                                            <td data-label={formatMessage(messages.paymentGateWay)}>{item && item.paymentName}</td>
                                            <td data-label={formatMessage(messages.status)}>
                                                <select name="isEnable" onChange={(e) => this.handleChange(e, item.id)} className={bt.selectInput} value={status}>
                                                    <option value="true">{formatMessage(messages.active)}</option>
                                                    <option value="false">{formatMessage(messages.inactive)}</option>
                                                </select>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}
const mapState = (state) => ({
});

const mapDispatch = {
    updatePaymentMethod
};
export default compose(injectIntl,
    withStyles(s, bt),
    connect(mapState, mapDispatch)
)(PaymentGatewayList);
