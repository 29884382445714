import showToaster from '../../../helpers/toastrMessgaes/showToaster';
import { updateTempImagesMutation } from '../../../lib/graphql';

export const updateTempImages = (tableName, fieldName, fileName) => {
  return async (dispatch, getState, { client }) => {

    try {
      const { data } = await client.mutate({
        mutation: updateTempImagesMutation,
        variables: {
          tableName,
          fieldName,
          fileName
        }
      });

      if (data?.updateTempImages?.status == 200) {
        showToaster({ messageId: 'tempImage', requestContent: fileName, toasterType: 'success' });
      } else {
        showToaster({ messageId: 'errorMessage', toasterType: 'error' });
      }
    } catch (error) {
      showToaster({ messageId: 'catchMessage', requestContent: error, toasterType: 'error' });
    }

  }
};